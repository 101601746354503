<template>
  <div
    class="container"
    v-loading="loading"
    element-loading-spinner="el-loading-spinner"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <div>
      <el-row>
        <el-col :span="2">
          <el-button @click="backToEdit" class="backButton">
            <el-icon>
              <Back />
            </el-icon>
            &nbsp; Back
          </el-button></el-col
        >
        <el-col :span="12" v-if="this.$route.query.isChatgpt">
          <el-steps :active="active" finish-status="success">
            <el-step title="Edit content"></el-step>
            <el-step title="Map fields"></el-step>
          </el-steps>
          <section v-if="this.active === 0">
            <br />
            <el-scrollbar wrap-style="max-height:80vh;" ref="configureScroll">
              <el-input
                v-model="chatgptContent"
                type="textarea"
                style="font-size: 16px; padding: 2px"
                ref="inputBox"
                id="myInputBox"
                @dblclick="makeSelectedWordAsVariable(chatgptContent)"
                autosize
              >
              </el-input>
            </el-scrollbar>
          </section>
          <section v-if="this.active === 1">
            <el-tabs v-model="activeName">
              <el-tab-pane label="Mapped Data" name="first">
                <br />
                <span class="text-mapFields">
                  Choose which data field you want to map your content using the
                  drop-downs below
                </span>
                <br />
                <br />
                <el-table
                  style="width: 100%"
                  height="600"
                  border
                  :data="actionAppData"
                  :row-style="rowStyle"
                >
                  <el-table-column :label="actionApp.name" prop="label">
                    <template v-slot="scope">
                      <span v-if="scope.row.required"
                        >{{ scope.row.label
                        }}<span style="color: red; font-size: larger"
                          >*</span
                        ></span
                      >
                      <span v-else>{{ scope.row.label }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="triggerApp.name" prop="label">
                    <template v-slot="scope">
                      <el-select
                        v-model="scope.row.trigger_app_field_name"
                        clearable
                        placeholder="select field"
                        style="width: 100%"
                        size="large"
                        @change="sendMappedData(scope.row)"
                      >
                        <el-option
                          v-for="fields in triggerAppData"
                          :key="fields.template_id"
                          :label="fields.label"
                          :value="fields.trigger_app_field_name"
                          :disabled="fields.isDisabled"
                        ></el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
            </el-tabs>
            <el-divider />
          </section>
          <div style="float: right" mt-2 v-if="this.active === 0">
            <el-button type="plain" class="cancelButton" @click="cancel"
              >Cancel</el-button
            >
            <el-button class="finishButton" @click="updateChatgptVariables"
              >Next</el-button
            >
          </div>
          <div style="float: right" mt-2 v-else>
            <el-button type="plain" class="cancelButton" @click="cancel"
              >Cancel</el-button
            >
            <el-button class="finishButton" @click="submitData"
              >Finish</el-button
            >
          </div>
        </el-col>
        <el-col :span="24" v-else>
          <el-tabs v-model="activeName">
            <el-tab-pane label="Mapped Data" name="first">
              <br />
              <span class="text-mapFields">
                Choose which data field you want to map your content using the
                drop-downs below
              </span>
              <br />
              <br />
              <el-table
                style="width: 100%"
                height="600"
                border
                :data="actionAppData"
                :row-style="rowStyle"
              >
                <el-table-column :label="actionApp.name" prop="label">
                  <template v-slot="scope">
                    <span v-if="scope.row.required"
                      >{{ scope.row.label
                      }}<span style="color: red; font-size: larger"
                        >*</span
                      ></span
                    >
                    <span v-else>{{ scope.row.label }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="triggerApp.name" prop="label">
                  <template v-slot="scope">
                    <el-select
                      v-model="scope.row.trigger_app_field_name"
                      clearable
                      placeholder="select field"
                      style="width: 100%"
                      size="large"
                      @change="sendMappedData(scope.row)"
                      @clear="handleClear(scope.row)"
                    >
                      <el-option
                        v-for="fields in triggerAppData"
                        :key="fields.template_id"
                        :label="fields.label"
                        :value="fields.trigger_app_field_name"
                        :disabled="disabledOptions[fields.trigger_app_field_name]">
                        <span v-if="fields.app_name" style="color:red">CHATGPT <span style="color:black">{{ fields.label
                            }}</span></span>
                        <span v-else>{{ fields.label }}</span>
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
          <el-row>
            <el-col :lg="24" class="mt-2" align="right">
              <el-button type="plain" class="cancelButton" @click="cancel"
                >Cancel</el-button
              >
              <el-button class="finishButton" @click="submitData"
                >Finish</el-button
              >
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import store from "../../store/store";
import { mapGetters } from "vuex";
import { ref } from "vue";
export default {
  components: {},
  data() {
    return {
      chatgptContent: null,
      disabledOptions: [],
      selectedOptions: [],
      triggerAppData: [],
      routeQuery: false,
      actionAppData: [],
      loading: false,
      activeName: "first",
      mappedData: [],
      allMappedData: [],
      isSelected: ref(true),
      actionApp: {
        name: null,
        entity_name: null,
        data: null,
      },
      triggerApp: {
        name: null,
        entity_name: null,
        data: null,
      },
      active: 0,
      paramsValues: null,
      chatgptVariables: [],
    };
  },
  computed: {
    ...mapGetters([
      "getAutomationFlowById",
      "getAppFields",
      "getMappedData",
      "getAllActions",
      "getActionInfo",
      "getTriggerAppFieldsInMapping",
      "getActionAppFieldsForMapping",
      "getActioStepData",
      "getUpdateChatgptVariables",
      "getGooglesheetsdata",
    ]),
  },
  async mounted() {
    this.loading = true;
    if (this.$route.query.isChatgpt) {
      this.getChatgptContent();
      this.loading = false;
    } else {
      this.editOrCreateMapping();
      this.loading = false;
    }
  },
  methods: {
    handleClear(row) {
      this.disabledOptions = this.disabledOptions.filter(
        (option) => option !== row.trigger_app_field_name
      );
      if (!this.disabledOptions) {
        this.disabledOptions = {};
      }
      this.selectedOptions = this.selectedOptions.filter(
        (option) => option !== row.trigger_app_field_name
      );

      row.trigger_app_field_name = null;
    },

    async getChatgptContent() {
      await store.dispatch("fetchActionStep", {
        actionId: this.$route.params.actionStepId,
      });
      this.currentActionStep = this.getActioStepData.data;
      this.chatgptContent = this.getActioStepData.data.output.content;
    },
    makeSelectedWordAsVariable() {
      const inputBox = document.getElementById("myInputBox");
      const selectedWord = this.getSelectedWord(inputBox);
      if (selectedWord) {
        const replacedVal = this.replaceSelectedWordWithVariable(
          selectedWord,
          inputBox
        );
        this.chatgptVariables.push({
          var_name: replacedVal.selectedWord,
          expression: replacedVal.expression,
        });
        this.chatgptContent = replacedVal.replacedContent;
      }
    },
    async updateChatgptVariables() {
      let params = {
        content: this.chatgptContent,
        chatgptVariables: this.chatgptVariables,
        actionId: this.$route.params.actionStepId,
      };
      await store.dispatch("updateChatgptVariables", {
        params,
      });
      if (this.getUpdateChatgptVariables) {
        this.$notify.success({
          title: "Success",
          message: "Content update in the event",
        });
        this.active = this.active + 1;
        this.editOrCreateMapping();
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in updating content in the event",
        });
      }
      //   this.chatgptResponse=false;
    },
    getSelectedWord(inputBox) {
      if (!inputBox) {
        return null;
      }
      const selection = window.getSelection();
      const selectedWord = selection.toString().trim();
      return selectedWord;
    },
    replaceSelectedWordWithVariable(selectedWord, inputBox) {
      if (!inputBox) {
        console.error("Input box not found");
        return null;
      }
      let expression = `<%${selectedWord}%>`;
      const content = inputBox.value;
      const start = inputBox.selectionStart;
      const end = inputBox.selectionEnd;

      const replacedContent =
        content.substring(0, start) + expression + content.substring(end);
      return {
        selectedWord: selectedWord,
        expression: expression,
        replacedContent: replacedContent,
      };
    },
    rowStyle() {
      return {
        height: "90px",
        color: "0E1216",
        fontSize: "14px",
        fontWeight: 500,
      };
    },
    goBack() {},
    async editOrCreateMapping() {
      if (this.$route.query.isEdit) {
        this.editMapping();
      } else {
        await this.fetchFileds();
      }
    },
    async fetchFileds() {
      this.paramsValues = this.$route.params;
      const automationFlowId = this.paramsValues.automationFlowId;
      this.loading = true;
      await store.dispatch("fetchAutomationFlowById", { id: automationFlowId });
      this.loading = false;
      if (this.getAutomationFlowById.data._id) {
        this.mainTainFields();
      }
    },
    async mainTainFields() {
      const recordId = this.paramsValues.actionStepId;
      let previousRecord;
      this.loading = true;
      await store.dispatch(
        "getAllActions",
        this.getAutomationFlowById.data._id
      );
      this.loading = false;
      let allEventsSteps = this.getAllActions.data;
      let index = allEventsSteps.findIndex((actionEvent) => {
        if (actionEvent && actionEvent._id === recordId) {
          return true;
        } else {
          return false;
        }
      });
      if (index < 0) {
        console.log("index is not found");
      } else {
        let record = allEventsSteps[index];
        previousRecord = allEventsSteps[index - 1];
        let params = {
          app_id: record.app_id,
          actionRecordId: record._id,
          entity_id:
            record.requiredIds && record.requiredIds.labels.length
              ? record.requiredIds.labels[0].model
              : "",
          entity_name: record.entity_name,
          eventType: record.eventType,
          connection_id: record.app_connection_id,
          user_id: this.getAutomationFlowById.data.user_id,
          operation: record.operation,
          spreadsheetId: record.spreadsheetId,
        };
        console.log("parasm----",params)
        this.loading = true;
        await store.dispatch("fetchActionAppFieldsForMapping", params);
        console.log("called-actionssss");
        console.log("getActionAppFieldsForMapping",this.getActionAppFieldsForMapping)
        this.actionApp.name =
          this.getActionAppFieldsForMapping.app_name +
          "   -  " +
          this.getActionAppFieldsForMapping.name;
          // console.log("actionnnnnnn",this.actionApp.name)
        this.actionAppData = this.getActionAppFieldsForMapping.fields;
        console.log("getActionAppFieldsForMapping",this.getActionAppFieldsForMapping)
        console.log("actionappdata",this.actionAppData)
      }
      this.loading = false;

      //trigger app
      let params = {
        app_id: this.getAutomationFlowById.data.app_id,
        entity_id:
          this.getAutomationFlowById.data.requiredIds &&
          this.getAutomationFlowById.data.requiredIds.labels
            ? this.getAutomationFlowById.data.requiredIds.labels[0].model
            : "",
        entity_name: this.getAutomationFlowById.data.entity_name,
        eventType: this.getAutomationFlowById.data.eventType,
        connection_id: this.getAutomationFlowById.data.app_connection_id,
        user_id: this.getAutomationFlowById.data.user_id,
        operation: this.getAutomationFlowById.data.operation,
        spreadsheetId: this.getAutomationFlowById.data.spreadsheetId,
      };
      if (
        previousRecord &&
        previousRecord.event_name &&
        previousRecord.event_name === "Ask to chatGpt"
      ) {
        params.actionEventpreviousRecordId = previousRecord._id;
      }
      this.loading = true;
      await store.dispatch("fetchTriggerAppFieldsForMapping", params);
      if (this.getTriggerAppFieldsInMapping) {
        this.triggerAppData = this.getTriggerAppFieldsInMapping.fields;
        this.triggerApp.name =
          this.getTriggerAppFieldsInMapping.app_name +
          "   -  " +
          this.getTriggerAppFieldsInMapping.name;
        this.loading = false;
      }
    },
    removeMappedData(row) {
      let indexValue = this.allMappedData.indexOf(row);
      this.allMappedData.splice(indexValue, 1);
      this.triggerAppData.push(row);
      // let matchedRecord = this.actionAppData.find(
      //   (item) => item.trigger_app_field_name == row.action_app_field_name
      // );
      // matchedRecord.isDisabled = false;
      row.action_app_field_name = "";
    },
    async sendMappedData(row) {
      console.log("row what is:", row);
      let matchedRecord = this.triggerAppData.find(
        (item) => item.trigger_app_field_name == row.trigger_app_field_name
      );
      if (matchedRecord) {
        console.log("matched row", matchedRecord);
        matchedRecord.isDisabled = true;
        this.selectedOptions[row.trigger_app_field_name] = true;
        // this.disabledOptions[row.trigger_app_field_name] = true;
        let obj = {
          trigger_app_field_name: row.trigger_app_field_name,
          action_app_field_name: row.action_app_field_name,
          ...(matchedRecord &&
            matchedRecord.template_id &&
            matchedRecord.template_id.length > 0 && {
              template_id: matchedRecord.template_id,
            }),
          ...(matchedRecord &&
            matchedRecord.app_name &&
            matchedRecord.app_name === "chatGpt" && {
              app_name: matchedRecord.app_name,
            }),
          label: row.label,
          required: row.required,
        };
        this.allMappedData.push(obj);
      }
    },
    async submitData() {
      let actionStepInfo = this.getAllActions.data.find(
        (item) => item._id == this.paramsValues.actionStepId
      );
      let mappedData = {
        _id: actionStepInfo._id,
        mapping_record: this.allMappedData,
      };
      if (mappedData.entity_id == null) {
        delete mappedData.entity_id;
      }
      await store.dispatch("createUpdateMapping", mappedData);
      if (this.getMappedData && this.getMappedData.status) {
        this.$notify.success({
          title: "Success",
          message: this.getMappedData.message,
        });
        this.$router.push(
          `/connector/workflow/${this.$route.params.automationFlowId}`
        );
      } else {
        this.$notify.error({
          title: "Error",  
          message: "failed to created mapped record",
        });
      }
    },
    async autoMap() {
      if (this.triggerApp.data.templates) {
        this.triggerAppData.forEach(async (row) => {
          if (this.actionApp.data.domain_fields) {
            console.log("2");
            await this.actionApp.data.domain_fields.map(async (item) => {
              let ItemtoLowerCase = item.key
                .toLowerCase()
                .replace(/[^\w\s-]/g, "")
                .replace(/[\s_-]+/g, "");
              let rowToLowerCase = row.trigger_app_field_name
                .toLowerCase()
                .replace(/[^\w\s-]/g, "")
                .replace(/[\s_-]+/g, "");
              if (ItemtoLowerCase == rowToLowerCase) {
                row.action_app_field_name = item.key;
                this.allMappedData.push(row);
                let indexValue = this.triggerAppData.indexOf(row);
                this.triggerAppData.splice(indexValue, 1);
              }
            });
          }
          await this.triggerApp.data.templates.forEach(async (temp) => {
            await temp.templateInfo.sections.forEach(async (item) => {
              await item.fields.forEach((field) => {
                if (row.trigger_app_field_name == field.key) {
                  row.template_id = temp.template_id;
                }
              });
            });
          });
        });
      } else if (this.actionApp.data.templates) {
        console.log("3");
        this.triggerAppData.forEach(async (row) => {
          this.actionApp.data.templates.forEach(async (temp) => {
            await temp.templateInfo.sections.forEach(async (item) => {
              await item.fields.forEach(async (field) => {
                let ItemtoLowerCase = field.key
                  .toLowerCase()
                  .replace(/[^\w\s-]/g, "")
                  .replace(/[\s_-]+/g, "");
                let rowToLowerCase = row.trigger_app_field_name
                  .toLowerCase()
                  .replace(/[^\w\s-]/g, "")
                  .replace(/[\s_-]+/g, "");
                if (ItemtoLowerCase == rowToLowerCase) {
                  row.action_app_field_name = field.key;
                  row.template_id = temp.template_id;
                  this.allMappedData.push(row);
                }
              });
            });
          });
        });
      } else {
        console.log("4");
        this.triggerAppData.forEach(async (row) => {
          if (this.actionApp.data.domain_fields) {
            await this.actionApp.data.domain_fields.map(async (item) => {
              let ItemtoLowerCase = item.key
                .toLowerCase()
                .replace(/[^\w\s-]/g, "")
                .replace(/[\s_-]+/g, "");
              let rowToLowerCase = row.trigger_app_field_name
                .toLowerCase()
                .replace(/[^\w\s-]/g, "")
                .replace(/[\s_-]+/g, "");
              if (ItemtoLowerCase == rowToLowerCase) {
                row.action_app_field_name = item.key;
                this.allMappedData.push(row);
              }
            });
          }
        });
      }
      if (this.allMappedData.length) {
        for (var item of this.allMappedData) {
          let matchedRecord = this.actionAppData.find(
            (row) => row.trigger_app_field_name == item.action_app_field_name
          );
          if (matchedRecord) {
            matchedRecord.isDisabled = true;
          }
        }
      }
    },
    async editMapping() {
      this.paramsValues = this.$route.params;
      const automationFlowId = this.paramsValues.automationFlowId;
      await store.dispatch("fetchAutomationFlowById", { id: automationFlowId });
      const recordId = this.paramsValues.actionStepId;
      await store.dispatch("getActionById", recordId);
      if (this.getActionInfo.status) {
        let matchedRecord = this.getActionInfo.data;
        if (matchedRecord && matchedRecord.mapping_record.length) {
          let existingRecord = matchedRecord.mapping_record;
          await this.mainTainFields();
          for (var item of existingRecord) {
            for (var record of this.actionAppData) {
              if (item.action_app_field_name == record.action_app_field_name) {
                let indexValue = this.actionAppData.indexOf(record);
                this.actionAppData.splice(indexValue, 1);
              }
            }
            this.actionAppData.push(item);
            this.allMappedData.push(item);
          }
          for (var triggerItem of existingRecord) {
            for (var triggerField of this.triggerAppData) {
              if (
                triggerField.trigger_app_field_name ==
                triggerItem.trigger_app_field_name
              ) {
                triggerField.isDisabled = true;
              }
            }
          }
        }
      }
    },
    backToEdit() {
      this.$confirm("Do You Want to Save Current Mapped fields", "Warning", {
        confirmButtonText: "Save",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(async () => {
          await this.submitData();
          this.$router.replace({
            path: `/connector/workflow/${this.paramsValues.automationFlowId}`,
          });
        })
        .catch(() => {
          this.$router.replace({
            path: `/connector/workflow/${this.paramsValues.automationFlowId}`,
          });
        });
    },
    cancel() {
      this.$confirm(
        "All the mapped fields will be lost if you cancel now.Do you want to proceed?",
        "Warning",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(async () => {
          await this.actionAppData.map((item) => {
            item.trigger_app_field_name = null;
          });
          await this.triggerAppData.map((item) => {
            item.isDisabled = false;
          });
        })
        .catch(() => {
          this.$message({
            title: "Warning",
            message: "Exit cancelled",
            type: "warning",
          });
        });
    },
    selectOrNot(x) {
      if (x == false) {
        this.isSelected = x;
      } else if (x != true && x != false) {
        (this.allMappedData = null), (this.allMappedData = x);
      }
    },
  },
};
</script>
<style>
.el-tabs__item {
  font-size: 16px;
}

.el-tab-pane {
  font-size: 17px;
}

.required-option {
  color: red;
}

.buttons {
  height: 70px;
}

.finishButton {
  background-color: #f754a2;
  border: none;
  height: 40px;
  width: 90px;
  font-weight: 600;
  color: #ffffff;
}

.finishButton:hover {
  background-color: #f754a2;
}

.backButton {
  height: 40px;
  width: 90px;
  font-weight: 600;
}

.cancelButton {
  height: 40px;
  width: 90px;
  color: "0E1216";
  font-weight: 600;
}

.text-mapFields {
  float: left;
  font-size: medium;
  color: #606266;
}


.finishButton:hover {
  background-color: #f754a2;
  color: #ffffff;
}

.chatgpt-card-text {
  font-size: medium;
  color: #606266;
  float: left;
}

.chatgpt-card {
  height: 80vh;
}

.cancel-finish {
  float: right;
}
</style>
