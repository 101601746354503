<template>
  <div v-loading="loading" element-loading-spinner="el-loading-spinner" element-loading-background="rgba(0, 0, 0, 0.1)">
    <el-tabs>
      <el-tab-pane label="Mapped Data">
        <br />
        <el-table height="400" border :data="tableData">
          <el-table-column label="Action App" prop="Action App">
            <template v-slot="scope">
              <span v-if="scope.row.required">{{ scope.row.label }}<span
                      style="color:red;font-size:larger">*</span></span>
              <span v-else>{{ scope.row.label }}</span>
          </template>
          </el-table-column>
          <el-table-column label="Trigger App" prop="Trigger App">
            <template v-slot="scope">
              <el-select v-model="scope.row.trigger_app_field_name"
                style="width:100%;" size="large">
                <el-option>{{ scope.row.trigger_app_field_name }}</el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import store from '../../store/store';
import { mapGetters } from "vuex";
export default {
  props: {
    actionStep: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tableData: null,
      loading : false,
    }
  },
  computed: {
        ...mapGetters([
            "getActionInfo"
        ]),
    },
  mounted() {
    this.viewMapping()
  },
  methods: {
    async viewMapping() {
      let recordId = this.actionStep.record_id
      this.loading = true;
      await store.dispatch("getActionById",recordId );
      this.loading = false;
      let matchedId =  this.getActionInfo.data
      if (matchedId && matchedId.mapping_record.length) {
        this.tableData = matchedId.mapping_record
      }
    },
  }
}

</script>
<style></style>