// import axios from "axios";
import { createStore } from 'vuex';
import axios from 'axios';
import appConfig from '../config/app'
// initial state
const store = createStore({
    state() {
        return {
            eventsByAppId: null,
            appById: null,
            updateTriggerAppData: null,
            updateActionAppData: null,
            quickbooksActions: null,
            allFlowsByUserId: null,
            loginResponse: null,
            createFlow: null,
            allApps: null,
            automationFlowById: null,
            deletedFlow: null,
            actionDeleteFlow: null,
            renamedFlow: null,
            status: null,
            tokens: null,
            esignsTokens: null,
            // entityList: null,
            appFields: null,
            quickbooksFieldsData: null,
            allConnections: null,
            existingTokens: null,
            getExistingMappings: null,
            corporationList: null,
            signupResponse: null,
            TwilioConnection: null,
            actionEventByAppId: null,
            mappedData: null,
            propertyTokens: null,
            actionApps: null,
            workflowsByPagination: null,
            automationFlowsByName: null,
            outlookCredintialsInfo: null,
            outlookTokensInfo: null,
            esignsWebhookResponse: null,
            latestAction: null,
            allActions: null,
            updatedActionConnection: null,
            actionInfo: null,
            actionSchedule: null,
            tokensResponse: null,
            serverUrl: null,
            oauthTokens: null,
            deleteConnection: null,
            updateConnectionName: null,
            saveCredentials: null,
            webhookInstructions: null,
            updateChatgptVariables: null,
            triggerAppFieldsInMapping: null,
            actionAppFieldsForMapping: null,
            actioStepData: null,
            // googlesheetsdata: null,
            createApp: null,
            // sheetsData: null,
            updateActionAppDataExistingConnection: null,
            fbPageWebhookResponse: null,
            // OneDriveFolders: null,
            // microsoftTeamsChats: null,
            webhookResponse: null,
            // slackChannels: null,
            // slackUsers: null,
            scheduleAutomation: null,
            appFieldsByName: null,
            nestedAppFields: null,
            childAppFields: null,
            refreshedAppFields: null,
            allLogs: null,
            eachLog: null
        };
    },
    getters: {
        getEventsByAppId(state) {
            return state.eventsByAppId
        },
        getActioStepData(state) {
            return state.actioStepData
        },
        getAppById(state) {
            return state.appById;
        },
        getUpdateTriggerAppData(state) {
            return state.updateTriggerAppData;
        },
        getUpdateActionAppData(state) {
            return state.updateActionAppData
        },
        getAllQuickbooksActions(state) {
            return state.quickbooksActions
        },
        getAllFlowsByUserId(state) {
            return state.allFlowsByUserId;
        },
        getLoginResponse(state) {
            return state.loginResponse
        },
        getAutomationFlow(state) {
            return state.createFlow
        },
        getTriggerApps(state) {
            return state.allApps
        },
        getAutomationFlowById(state) {
            return state.automationFlowById
        },
        getTokens(state) {
            return state.tokens
        },
        getDeleteFlow(state) {
            return state.deletedFlow
        },
        getDeleteActionFlow(state) {
            return state.actionDeleteFlow
        },
        getRenameFlow(state) {
            return state.renamedFlow
        },
        getAutomationStatus(state) {
            return state.status
        },
        getEsignsTokens(state) {
            return state.esignsTokens
        },
        // getEntityList(state) {
        //     return state.entityList
        // },
        getAppFields(state) {
            return state.appFields
        },
        getQuickbooksFields(state) {
            return state.quickbooksFieldsData
        },
        getAllConnections(state) {
            return state.allConnections
        },
        getexistingTokens(state) {
            return state.existingTokens
        },
        getExistingMapping(state) {
            return state.getExistingMappings
        },
        // getAllCorporationsList(state) {
        //     return state.corporationList
        // },
        getUserSignupResponse(state) {
            return state.signupResponse
        },
        getTwilioConnection(state) {
            return state.TwilioConnection
        },
        getActionEventByAppId(state) {
            return state.actionEventByAppId
        },
        getMappedData(state) {
            return state.mappedData
        },
        getPropertyTokens(state) {
            return state.propertyTokens
        },
        getActionApps(state) {
            return state.actionApps
        },
        getisFlowActivated(state) {
            return state.isFlowActivated
        },
        getWorkflowsByPagination(state) {
            return state.workflowsByPagination
        },
        getAutomationFlowBySearch(state) {
            return state.automationFlowsByName
        },
        getOutlookCredintialsInfo(state) {
            return state.outlookCredintialsInfo
        },
        getOutlookTokensInfo(state) {
            return state.outlookTokensInfo
        },
        getEsignsWebhookResponse(state) {
            return state.esignsWebhookResponse
        },
        getLatestAction(state) {
            return state.latestAction
        },
        getAllActions(state) {
            return state.allActions
        },
        getUpdateActionAppDataConnection(state) {
            return state.updatedActionConnection
        },
        getActionInfo(state) {
            return state.actionInfo
        },
        getActionSchedule(state) {
            return state.actionSchedule
        },
        getTokensWithConnection(state) {
            return state.tokensResponse
        },
        getOauthServerUrl(state) {
            return state.serverUrl
        },
        getOauthTokens(state) {
            return state.oauthTokens
        },
        getLabels(state) {
            return state.appContent
        },
        getValidateAndSaveCredentials(state) {
            return state.saveCredentials
        },
        getDeleteConnection(state) {
            return state.deleteConnection
        },
        getUpdateConnectionName(state) {
            return state.updateConnectionName
        },
        getWebhookInstructions(state) {
            return state.webhookInstructions
        },
        getUpdateChatgptVariables(state) {
            return state.updateChatgptVariables
        },
        getCreateApp(state) {
            return state.createApp
        },
        getTriggerAppFieldsInMapping(state) {
            return state.triggerAppFieldsInMapping
        },
        getActionAppFieldsForMapping(state) {
            return state.actionAppFieldsForMapping
        },
        // getGooglesheetsdata(state) {
        //     return state.googlesheetsdata
        // },
        // getSheetsdata(state) {
        //     return state.sheetsData
        // },
        getUpdateActionAppDataExistingConnection(state) {
            return state.updateActionAppDataExistingConnection
        },
        getFbPageWebhookResponse(state) {
            return state.fbPageWebhookResponse
        },
        // getAllOneDriveFolders(state) {
        //     return state.OneDriveFolders
        // },
        // getAllTeamsChats(state) {
        //     return state.microsoftTeamsChats
        // },
        // getWebhookThirdParty(state){
        getWebhookThirdParty(state) {
            return state.webhookResponse
        },
        // getAllSlackChannels(state) {
        //     return state.slackChannels
        // },
        // getAllSlackUsers(state) {
        //     return state.slackUsers
        // },
        // getFaceBookPages(state) {
        //     return state.faceBookPages
        // },
        getScheduleAutomation(state) {
            return state.scheduleAutomation
        },
        getAppFieldsByName(state) {
            return state.appFieldsByName
        },
        getNestedAppFields(state) {
            return state.nestedAppFields
        },
        getChildAppFields(state) {
            return state.childAppFields
        },
        getRefreshedAppFields(state) {
            return state.refreshedAppFields
        },
        getAllLogs(state) {
            return state.allLogs
        },
        getEachLog(state) {
            return state.eachLog
        }
    },
    mutations: {
        setEventsByAppId(state, data) {
            state.eventsByAppId = data
        },
        setActioStepData(state, data) {
            state.actioStepData = data
        },
        setAppById(state, data) {
            state.appById = data;
        },
        setActionEventsByAppId(state, data) {
            state.actionEventByAppId = data
        },
        setUpdateTriggerAppData(state, data) {
            state.updateTriggerAppData = data;
        },
        setUpdateActionAppData(state, data) {
            state.updateActionAppData = data;
        },
        setAllQuickbooksActions(state, data) {
            state.quickbooksActions = data
        },
        setAllFlowsByUserId(state, data) {
            state.allFlowsByUserId = data;
        },
        setLoginResponse(state, data) {
            state.loginResponse = data
        },
        setAutomationFlow(state, data) {
            state.createFlow = data
        },
        setTriggerApps(state, data) {
            state.allApps = data
        },
        setPipeLine(state, data) {
            state.piplineInfo = data
        },
        setUpdatePipeLine(state, data) {
            state.updatePipelineInfo = data
        },
        setPipelineId(state, data) {
            state.pipelineId = data
        },
        setAutomationFlowById(state, data) {
            state.automationFlowById = data;
        },
        setTokens(state, data) {
            state.tokens = data
        },
        setDeleteFlow(state, data) {

            state.deletedFlow = data
        },
        setActionDeleteFlow(state, data) {
            state.actionDeleteFlow = data
        },
        setRenameFlow(state, data) {
            state.renamedFlow = data
        },
        setStatus(state, data) {
            state.status = data
        },
        setEsignsTokens(state, data) {
            state.esignsTokens = data
        },
        // setEntityList(state, data) {
        //     state.entityList = data
        // },
        setAppFields(state, data) {
            state.appFields = data
        },
        setQuickbooksFields(state, data) {
            state.quickbooksFieldsData = data
        },
        setConnections(state, data) {
            state.allConnections = data
        },
        setExistingTokens(state, data) {
            state.existingTokens = data
        },
        setExistingMapping(state, data) {
            state.getExistingMappings = data
        },
        // setCorporations(state, data) {
        //     state.corporationList = data
        // },
        setUserData(state, data) {
            state.signupResponse = data
        },
        setTwilioConnection(state, data) {
            state.TwilioConnection = data
        },
        setMappedData(state, data) {
            state.mappedData = data
        },
        setPropertyTokens(state, data) {
            state.propertyTokens = data
        },
        setActionApps(state, data) {
            state.actionApps = data
        },
        setActivateFlow(state, data) {
            state.isFlowActivated = data
        },
        setworkflowPagination(state, data) {
            state.workflowsByPagination = data
        },
        setworkflowByName(state, data) {
            state.automationFlowsByName = data
        },
        setOutlookCredintialsInfo(state, data) {
            state.outlookCredintialsInfo = data
        },
        setOutlookTokensInfo(state, data) {
            state.outlookTokensInfo = data
        },
        setEsignsWebhook(state, data) {
            state.esignsWebhookResponse = data
        },
        setLatestAction(state, data) {
            state.latestAction = data
        },
        setAllActions(state, data) {
            state.allActions = data
        },
        setUpdateActionAppDataConnection(state, data) {
            state.updatedActionConnection = data
        },
        setUpdateActionAppDataExistingConnection(state, data) {
            state.updateActionAppDataExistingConnection = data
        },
        setActionInfo(state, data) {
            state.actionInfo = data
        },
        setActionSchedule(state, data) {
            state.actionSchedule = data
        },
        setTokensWithConnection(state, data) {
            state.tokensResponse = data
        },
        setOauthAppCredintials(state, data) {
            state.serverUrl = data
        },
        setOauthTokens(state, data) {
            state.oauthTokens = data
        },
        setLabels(state, data) {
            state.appContent = data
        },
        setCredentials(state, data) {
            state.saveCredentials = data
        },
        setDeleteConnection(state, data) {
            state.deleteConnection = data
        },
        setUpdateConnectionName(state, data) {
            state.updateConnectionName = data
        },
        setWebhookInstructions(state, data) {
            state.webhookInstructions = data
        },
        setUpdateChatgptVariables(state, data) {
            state.updateChatgptVariables = data
        },
        setTriggerAppFieldsInMapping(state, data) {
            state.triggerAppFieldsInMapping = data;
        },
        setActionAppFieldsForMapping(state, data) {
            state.actionAppFieldsForMapping = data;
        },
        // setGooglesheetsdata(state, data) {
        //     state.googlesheetsdata = data;
        // },
        setCreateApp(state, data) {
            state.createApp = data
        },
        // setSheetsdata(state, data) {
        //     state.sheetsData = data;
        // },
        setFbPageWebhook(state, data) {
            state.fbPageWebhookResponse = data;
        },
        // setFolders(state, data) {
        //     state.OneDriveFolders = data;
        // },
        // setTeamsChats(state, data) {
        //     state.microsoftTeamsChats = data;
        // },
        // setWebhookThirdParty(state, data){
        setWebhookThirdParty(state, data) {
            state.webhookResponse = data;
        },
        // setChannels(state, data) {
        //     state.slackChannels = data;
        // },
        // setUsers(state, data) {
        //     state.slackUsers = data;
        // },
        // setFacebookPages(state, data) {
        //     state.faceBookPages = data
        // },
        setScheduleAutomation(state, data) {
            state.scheduleAutomation = data
        },
        setAppFieldsByName(state, data) {
            state.appFieldsByName = data
        },
        setNestedAppFieldsByName(state, data) {
            state.nestedAppFields = data
        },
        setChildAppFieldsByName(state, data) {
            state.childAppFields = data
        },
        setRefreshedFields(state, data) {
            state.refreshedAppFields = data
        },
        setAllLogs(state, data) {
            state.allLogs = data
        },
        setEachLog(state, data) {
            state.eachLog = data
        },
    },
    actions: {
        /* ***************************************************************************************** */
        // async addAppEvents(context,params){
        //     try{
        //         context.commit('setCreateApp', null, { root: true });
        //         let response = await axios.post(`${appConfig.app.connectorAppApi}/executeAutomaionFlow/run-cron-job`,params)
        //         context.commit('setCreateApp', response.data, {
        //             root: true
        //         });
        //     }catch(err){
        //         console.log("err",err);
        //     }
        // },
        async createAutomationFlow(context, params) {//1
            let response = null;
            try {
                context.commit('setAutomationFlow', null, { root: true });
                response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/create-workflow`, params);
                context.commit('setAutomationFlow', response.data, { root: true });
            }
            catch (err) {
                console.log("Error while creating Automation workflow", err);
            }
        },
        async fetchAutomationFlowById(context, params) {//2
            try {
                context.commit('setAutomationFlowById', null, { root: true });
                let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/get-workflow/${params.id}`)
                context.commit('setAutomationFlowById', response.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("error while fetching automation flow", err);
            }
        },
        async fetchAllFlowsByUserId(context, params) {//3
            console.log("getting-flowss")
            try {
                context.commit('setAllFlowsByUserId', null, { root: true });
                let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/get-workflows-list?user_id=${params.user_id}`)
                context.commit('setAllFlowsByUserId', response.data.data, { root: true });
            }
            catch (err) {
                console.log("error while fetching all workflows", err);
            }
            // }
        },
        async fetchAllTriggerApps(context, params) {//4
            try {
                context.commit('setTriggerApps', null, { root: true });
                let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/getAllApps/${params}`)
                context.commit('setTriggerApps', response.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("error while fetching apps", err);
            }
        },
        async getAllActions(context, params) {//5
            try {
                let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/get-all-actions/${params}`)
                console.log("response.data,,,", response.data)
                context.commit('setAllActions', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setAllActions', null, {
                    root: true
                });
            }
        },
        // async fetchEntitiesList(context, params) {//6
        //     try {
        //         let response = await axios.get(`${appConfig.app.connectorAppApi}/esigns/fetch-esigns-entities?connection_id=${params.connection_id}&user_id=${params.user_id}`)
        //         context.commit('setEntityList', response.data, {
        //             root: true
        //         });
        //     }
        //     catch (err) {
        //         context.commit('setEntityList', null, {
        //             root: true
        //         });
        //     }
        // },
        async updateAutomationFlowWithTrigger(context, params) {//6
            try {
                context.commit('setUpdateTriggerAppData', null, {
                    root: true
                });
                let response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/update-automation-with-Trigger-app-info/${params.automationId}`, params);
                context.commit('setUpdateTriggerAppData', response.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("error while fetching trigger events", err);
            }
        },
        async updateTriggerAppInfoInFlow(context, params) {//7
            try {
                context.commit('setUpdateTriggerAppData', null, {
                    root: true
                });
                let response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/update-tiggerApp-with-connection/${params.flowId}`, params);
                context.commit('setUpdateTriggerAppData', response.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("error while fetching trigger events", err);
            }
        },
        async fetchLatestAction(context, params) {//8
            try {
                let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/get-latest-action-of-user-with-automation/${params}`)
                context.commit('setLatestAction', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setLatestAction', null, {
                    root: true
                });
            }
        },
        async updateActionAppInfoInFlow(context, params) {//9
            try {
                context.commit('setUpdateActionAppData', null, {
                    root: true
                });
                let response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/create-action-step/${params.flowId}`, params);
                context.commit('setUpdateActionAppData', response.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("error while fetching trigger events", err);
            }
        },
        async fetchTokens(context, params) {//10
            let response = null;
            try {
                context.commit('setTokens', null, { root: true });
                response = await axios.get(`${appConfig.app.connectorAppApi}/oauth/getTokens?code=${params.code}&realmId=${params.realmId}&user_id=${params.user_id}`)
                context.commit('setTokens', response.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("Error while fetching tokens", err);
            }
        },
        async updateActionAppInfoInFlowConnection(context, params) {//11
            try {
                context.commit('setUpdateActionAppDataConnection', null, {
                    root: true
                });
                let response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/update-actionApp-data/${params.actionId}`, params);
                context.commit('setUpdateActionAppDataConnection', response.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("error while fetching trigger events", err);
            }
        },
        async updateActionwithExistingConnection(context, params) {
            try {
                context.commit('setUpdateActionAppDataExistingConnection', null, {
                    root: true
                });
                let response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/update-actionApp-data-existing-connection/${params.connection_id}/${params.actionId}`);
                context.commit('setUpdateActionAppDataExistingConnection', response.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("error while fetching trigger events", err);
            }
        },
        async fetchAppFields(context, params) {//12
            try {
                let response = await axios.get(`${appConfig.app.connectorAppApi}/esigns/fetch-esigns-entities-by-id/`, { params: params })
                context.commit('setAppFields', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setAppFields', null, {
                    root: true
                });
            }
        },
        async createUpdateMapping(context, params) {//13
            try {
                context.commit('setMappedData', null, { root: true });
                let response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/create-mapping/${params._id}`, params)
                context.commit('setMappedData', response.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("Error while fetching tokens", err);
            }
        },
        async addWebhookInEsigns(context, params) {
            try {
                let response = await axios.post(`${appConfig.app.connectorAppApi}/esigns/add-webhook-url/${params.automationId}`, params)
                context.commit('setEsignsWebhook', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setEsignsWebhook', null, {
                    root: true
                });
            }
        },
        async userLogin(context, params) {//15
            try {
                let response = await axios.post(`${appConfig.app.connectorAppApi}/users/login`, params)
                console.log('Login successful. Response:', response.data);
                context.commit('setLoginResponse', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setLoginResponse', null, {
                    root: true
                });
            }
        },
        async userSignup(context, params) {//16
            try {
                let response = await axios.post(`${appConfig.app.connectorAppApi}/users/signup`, params)
                context.commit('setUserData', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setUserData', null, {
                    root: true
                });
            }
        },
        async makeAutomationFlowActivate(context, params) {//17
            try {
                context.commit('setActivateFlow', null, { root: true });
                let response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/activate-deactivate-automationFlow/${params.id}/${params.isActivate}`)
                context.commit('setActivateFlow', response.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("Error while fetching tokens", err);
            }
        },
        async getWorkflowsByPagination(context, params) {//18
            try {
                context.commit('setworkflowPagination', null, { root: true });
                let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/getworkflows`, { params: params })
                context.commit('setworkflowPagination', response.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("Error while fetching tokens", err);
            }
        },
        async searchWorkflowByName(context, params) {//19
            try {
                context.commit('setworkflowByName', null, { root: true });
                let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/search-automation`, { params: params })
                context.commit('setworkflowByName', response.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("Error while fetching tokens", err);
            }
        },
        async deleteWorkflow(context, workflowId) {//20
            try {
                console.log("workflowId", workflowId)
                const response = await axios.delete(`${appConfig.app.connectorAppApi}/automationFlow/delete-workflow/${workflowId}`)
                context.commit('setDeleteFlow', response.data, { root: true });
            }
            catch (error) {
                console.error('An error occurred while deleting the workflow', error);
            }
        },
        async deleteActionStep(context, actionId) {
            try {
                console.log("actionId", actionId)
                const response = await axios.delete(`${appConfig.app.connectorAppApi}/automationFlow/delete-action-step/${actionId}`)
                context.commit('setActionDeleteFlow', response.data, { root: true });
            }
            catch (error) {
                console.error('An error occurred while deleting the action step', error);
            }
        },
        async fetchLabels(context, appName) {
            try {
                console.log("appName", appName)
                const response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/fetch-app-content/${appName}`)
                context.commit('setLabels', response.data, { root: true });
            }
            catch (error) {
                context.commit('setLabels', null, {
                    root: true
                });
                console.error('An error occurred while fetching the labels', error)
            }
        },
        async validateAndSaveCredentials(context, { appName, userId, params }) {
            try {
                console.log("validate-Credentials-save", appName)
                const response = await axios.post(`${appConfig.app.connectorAppApi}/oauth/validate-and-save-credintials/${appName}/${userId}`, params)
                context.commit('setCredentials', response.data, { root: true });
            }
            catch (error) {
                context.commit('setCredentials', null), {
                    root: true
                }
                console.error('An error occurred while verify and saving the credentials', error)
            }
        },
        async webhookInstructions(context, appName) {
            try {
                const response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/webhook-instructions/${appName}`)
                context.commit('setWebhookInstructions', response.data, { root: true });
            }
            catch (error) {
                context.commit('setWebhookInstructions', null), {
                    root: true
                }
                console.error('An error occurred while fetching the instructions', error)
            }
        },
        async renameAutomationById(context, params) {//21
            try {
                const response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/renameAutomation`, params)
                context.commit('setRenameFlow', response.data, { root: true });
            }
            catch (error) {
                context.commit('setRenameFlow', null, {
                    root: true
                });
                console.error('An error occurred while renaming the workflow', error);
            }
        },
        async fetchActionStep(context, params) {
            try {
                context.commit('setActioStepData', null, { root: true });
                let response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/fetchActionRecord/${params.actionId}`)
                context.commit('setActioStepData', response.data, {
                    root: true
                });
            } catch (err) {
                console.log("what is err", err);
            }
        },
        async scheduleAction(context, params) {//22
            try {
                const response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/add-update-scheduling/${params.item.record_id}`, params.parsedDate)
                context.commit('setActionSchedule', response.data, { root: true });
            }
            catch (error) {
                context.commit('setActionSchedule', null, {
                    root: true
                });
            }
        },
        async validateKeyByMakingReq(context, params) {
            let response = null;
            try {
                context.commit('setChatgptResult', null, { root: true });
                response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/validate-chatgpt-key`, params);
                context.commit('setChatgptResult', response.data, { root: true });
            } catch (error) {
                console.log("error in getting chatgpt content", error);
            }
        },
        async updateChatgptVariables(context, params) {
            let response = null;
            try {
                context.commit('setUpdateChatgptVariables', null, { root: true });
                response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/update-chatgpt-variables`, params);
                context.commit('setUpdateChatgptVariables', response.data.data, { root: true });
            } catch (error) {
                console.log("error in updating chatgpt variables");
            }
        },
        async fetchTriggerAppFieldsForMapping(context, params) {
            let response = null;
            try {
                context.commit('setTriggerAppFieldsInMapping', null, { root: true });
                response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/get-triggerapp-fields-for-mapping/${params.app_id}`, params);
                context.commit('setTriggerAppFieldsInMapping', response.data.data, { root: true });
            } catch (error) {
                console.log("error in getting TriggerAppFields");
            }
        },
        async fetchActionAppFieldsForMapping(context, params) {
            let response = null;
            try {
                context.commit('setActionAppFieldsForMapping', null, { root: true });
                response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/get-actionapp-fields-for-mapping/${params.app_id}`, params);
                context.commit('setActionAppFieldsForMapping', response.data.data, { root: true });
            } catch (error) {
                console.log("error in getting TriggerAppFields");
            }
        },
        // async fetchSheetsList(context, params) {
        //     try {
        //         context.commit('setSheetsdata', null, {
        //             root: true
        //         });
        //         let response = await axios.post(`${appConfig.app.connectorAppApi}/sheets/get-sheets-of-spreadsheet`, params);
        //         console.log('Response Data:', response.data);
        //         context.commit('setSheetsdata', response.data, {
        //             root: true
        //         });
        //     }
        //     catch (err) {
        //         console.log("error while fetching sheets", err);
        //     }
        // },
        // async getAllCorporations(context, params) {
        //     try {
        //         context.commit('setCorporations', null, {
        //             root: true
        //         });
        //         let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/fetchAllcorporationList/${params.connection_id}`);
        //         context.commit('setCorporations', response.data, {
        //             root: true
        //         });
        //     }
        //     catch (err) {
        //         console.log("error while fetching sheets", err);
        //     }
        // },
        // async fetchAllOneDriveFolders(context, params) {
        //     try {
        //         context.commit('setFolders', null, {
        //             root: true
        //         });
        //         let response = await axios.post(`${appConfig.app.connectorAppApi}/sheets/get-folders-of-oneDrive`, params);
        //         context.commit('setFolders', response.data, {
        //             root: true
        //         });
        //     }
        //     catch (err) {
        //         console.log("error while fetching Folders", err);
        //     }
        // },
        // async fetchAllSlackChannels(context, params) {
        //     try {
        //         context.commit('setChannels', null, {
        //             root: true
        //         });
        //         let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/getConversationlistFromSlack/${params.connection_id}`, params);
        //         context.commit('setChannels', response.data, {
        //             root: true
        //         });
        //     }
        //     catch (err) {
        //         console.log("error while fetching Channels", err);
        //     }
        // },
        // async fetchAllMicrosoftTeamsChats(context, params) {
        //     try {
        //         context.commit('setTeamsChats', null, {
        //             root: true
        //         });
        //         let response = await axios.post(`${appConfig.app.connectorAppApi}/automationflow/getChatMembersFromTeams/${params.connection_id}`, params);
        //         context.commit('setTeamsChats', response.data, {
        //             root: true
        //         });
        //     }
        //     catch (err) {
        //           console.log("error while fetching Users", err);
        //     }
        // },

        // async fetchAllSlackUsers(context, params) {
        //     try {
        //         context.commit('setUsers', null, {
        //             root: true
        //         });
        //         let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/getSlackUsers/${params.connection_id}`);
        //         context.commit('setUsers', response.data, {
        //             root: true
        //         });
        //     }
        //     catch (err) {
        //         console.log("error while fetching TeamsChats", err);
        //     }
        // },
        /* *************************************************************************************** */


        async creationActionStep(context, params) {
            try {
                context.commit('setUpdateActionAppData', null, {
                    root: true
                });
                let response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/${params.flowId}`, params);
                context.commit('setUpdateActionAppData', response.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("error while fetching trigger events", err);
            }
        },
        async fetchAllActionApps(context, params) {
            try {
                context.commit('setActionApps', null, { root: true });
                let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/getAllApps/${params}`)
                context.commit('setActionApps', response.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("error while fetching apps", err);
            }
        },
        async fetchTriggerEventsByAppId(context, params) {
            try {
                context.commit('setEventsByAppId', null, {
                    root: true
                });
                let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/get-tigger-events/${params.app_id}`)
                context.commit('setEventsByAppId', response.data.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("error while fetching trigger events", err);
            }
        },
        async fetchActionEventsByAppId(context, params) {
            try {
                context.commit('setActionEventsByAppId', null, {
                    root: true
                });
                let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/get-action-events/${params.app_id}`)
                context.commit('setActionEventsByAppId', response.data.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("error while fetching Action events", err);
            }
        },
        async getActionById(context, params) {
            try {
                context.commit('setActionInfo', null, {
                    root: true
                });
                let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/get-action-step-by-id/${params}`);
                context.commit('setActionInfo', response.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("error while fetching trigger events", err);
            }
        },
        async fetchAppById(context, params) {
            let response = null;
            try {
                context.commit('setAppById', null, { root: true });
                response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/get-app/${params.id}`)
                context.commit('setAppById', response.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("Error while fetching tokens", err);
            }
        },
        async getPropertyTokens(context, params) {
            try {
                context.commit('setPropertyTokens', null, { root: true });
                let response = await axios.post(`${appConfig.app.connectorAppApi}/property/get-tokens`, params)
                context.commit('setPropertyTokens', response.data, {
                    root: true
                });
            }
            catch (err) {
                console.log("Error while fetching tokens", err);
            }
        },
        async automationFlowByStatus(context, params) {
            try {
                const response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/getAutomationFlowsByStatus?user_id=${params.user_id}&status=${params.status}`)
                context.commit('setStatus', response.data, { root: true });
            }
            catch (error) {
                context.commit('setStatus', null, {
                    root: true
                });
            }
        },
        async saveOutlookCredintials(context, params) {
            try {
                const response = await axios.post(`${appConfig.app.connectorAppApi}/outlook/save-outlook-tokens/${params.user_id}`, params.outlookCredintials)
                context.commit('setOutlookCredintialsInfo', response.data, { root: true });
            }
            catch (error) {
                context.commit('setOutlookCredintialsInfo', null, {
                    root: true
                });
                console.error('An error occurred while renaming the workflow', error);
            }
        },
        async fetchTokensOutlook(context, params) {
            try {
                const response = await axios.post(`${appConfig.app.connectorAppApi}/oauth/get-access-token/${params.user_id}`, params)
                context.commit('setOutlookTokensInfo', response.data, { root: true });
            }
            catch (error) {
                context.commit('setOutlookTokensInfo', null, {
                    root: true
                });
                console.error('An error occurred while renaming the workflow', error);
            }
        },
        async action(context, params) {
            try {
                let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/get-action-events/` + params)
                context.commit('setAllQuickbooksActions', response.data.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setAllQuickbooksActions', null, {
                    root: true
                });
            }
        },
        async esignsTokensInfo(context, params) {
            try {
                let response = await axios.post(`${appConfig.app.connectorAppApi}/oauth/tokens-from-esigns`, params)
                context.commit('setEsignsTokens', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setEsignsTokens', null, {
                    root: true
                });
            }
        },
        async quickbooksFields(context, params) {
            try {
                let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/fetch-qb-fields/` + params)
                context.commit('setQuickbooksFields', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setQuickbooksFields', null, {
                    root: true
                });
            }
        },
        async fetchExisistingConnections(context, params) {
            try {
                let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/get-existing-connections-of-app-with-user/${params.appName}/${params.user_id}`)
                context.commit('setConnections', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setConnections', null, {
                    root: true
                });
            }
        },
        async fetchExisistingTokens(context, params) {
            try {
                let response = await axios.get(`${appConfig.app.connectorAppApi}/users/get-existing-tokens`, { params })
                context.commit('setExistingTokens', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setExistingTokens', null, {
                    root: true
                });
            }
        },
        async getExistingMappingApi(context, params) {
            try {
                let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/get-existing-mapping`, { params: params })
                context.commit('setExistingMapping', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setExistingMapping', null, {
                    root: true
                });
            }
        },
        async getTwilioTokens(context, params) {
            try {
                let response = await axios.post(`${appConfig.app.connectorAppApi}/twilio/save-twilio-tokens`, params)
                context.commit('setTwilioConnection', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setTwilioConnection', null, {
                    root: true
                });
            }
        },
        // async saveConnection(context, params) {
        //     try {
        //         let response = await axios.post(`${appConfig.app.connectorAppApi}/oauth/save-tokens`, params)
        //         context.commit('setTokensWithConnection', response.data, {
        //             root: true
        //         });
        //     }
        //     catch (err) {
        //         context.commit('setTokensWithConnection', null, {
        //             root: true
        //         });
        //     }
        // },
        async getOauthAppServerUrl(context, params) {
            try {
                let response = await axios.get(`${appConfig.app.connectorAppApi}/oauth/find-oauthapp-credintials/${params}`)
                context.commit('setOauthAppCredintials', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setOauthAppCredintials', null, {
                    root: true
                });
            }
        },
        async getOauthTokens(context, params) {
            try {
                let response = await axios.post(`${appConfig.app.connectorAppApi}/oauth/get-tokens-by-exchanging-authorization-code/${params.user_id}`, params)
                context.commit('setOauthTokens', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setOauthTokens', null, {
                    root: true
                });
            }
        },
        async deleteConnection(context, params) {
            try {
                let response = await axios.delete(`${appConfig.app.connectorAppApi}/oauth/delete-connection/${params}`)
                context.commit('setDeleteConnection', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setDeleteConnection', null, {
                    root: true
                });
            }
        },
        async updateConnectionName(context, params) {
            try {
                let response = await axios.post(`${appConfig.app.connectorAppApi}/oauth/update-connection-name`, params)
                context.commit('setUpdateConnectionName', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setUpdateConnectionName', null, {
                    root: true
                });
            }
        },
        async addWebhookInFaceBookPage(context, params) {
            try {
                let response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/add-webookurl-in-facebookpage/${params.automationId}`)
                context.commit('setFbPageWebhook', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setFbPageWebhook', null, {
                    root: true
                });
            }
        },
        async registerWebhookInThirdParty(context, params) {
            try {
                let response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/add-webookurl-in-thirdParty`, params)
                context.commit('setWebhookThirdParty', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setWebhookThirdParty', null, {
                    root: true
                });
            }
        },
        // async fetchFaceBookPages(context, params) {
        //     try {
        //         let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/fetch-facebook-pages/${params}`)
        //         context.commit('setFacebookPages', response.data, {
        //             root: true
        //         });
        //     }
        //     catch (err) {
        //         context.commit('setFacebookPages', null, {
        //             root: true
        //         });
        //     }
        // },
        async fetchAppFieldsByName(context, params) {
            try {
                let response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/fetch-fields-by-appName`, params)
                context.commit('setAppFieldsByName', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setAppFieldsByName', null, {
                    root: true
                });
            }
        },
        // async fetchYouTubeChannels(context, params) {
        //     try {
        //         let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/fetch-youtube-channels/${params}`)
        //         context.commit('setYouTubeChannels', response.data, {
        //             root: true
        //         });
        //     }
        //     catch (err) {
        //         context.commit('setYouTubeChannels', null, {
        //             root: true
        //         });
        //     }
        // },
        async scheduleAutomation(context, params) {
            try {
                let response = await axios.post(`${appConfig.app.connectorAppApi}/cron/schedule-automation-cronjob`, params)
                context.commit('setScheduleAutomation', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setScheduleAutomation', null, {
                    root: true
                });
            }
        },
        // async fetchMsWorkbookList(context, params) {
        //     try {
        //         let response = await axios.get(`${appConfig.app.connectorAppApi}/automationFlow/fetchAllmicrosoftExcelWorkbooksList/${params}`)
        //         context.commit('setWorkbookList', response.data, {
        //             root: true
        //         });
        //     }
        //     catch (err) {
        //         context.commit('setWorkbookList', null, {
        //             root: true
        //         });
        //     }
        // },
        async fetchNestedAppFieldsByName(context, params) {
            try {
                let response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/fetch-nested-fields-by-appName`, params)
                context.commit('setNestedAppFieldsByName', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setNestedAppFieldsByName', null, {
                    root: true
                });
            }
        },
        async fetchChildFieldsByName(context, params) {
            try {
                let response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/fetch-child-fields-by-appName`, params)
                context.commit('setChildAppFieldsByName', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setChildAppFieldsByName', null, {
                    root: true
                });
            }
        },
        async fetchRefreshingFields(context, params) {
            try {
                let response = await axios.post(`${appConfig.app.connectorAppApi}/automationFlow/refresh-fields`, params)
                context.commit('setRefreshedFields', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setRefreshedFields', null, {
                    root: true
                });
            }
        },
        async fetchAllLogs(context, params) {
            try {
                let response = await axios.post(`${appConfig.app.connectorAppApi}/logs/fetch-logs-by-automation`, params)
                context.commit('setAllLogs', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setAllLogs', null, {
                    root: true
                });
            }
        },
        async getLogDetailsByKey(context, params) {
            try {
                let response = await axios.post(`${appConfig.app.connectorAppApi}/logs/filter-logs-by-key`, params)
                context.commit('setEachLog', response.data, {
                    root: true
                });
            }
            catch (err) {
                context.commit('setEachLog', null, {
                    root: true
                });
            }
        },
    }
})
export default store;