import store from "@/store/store"
import { mapGetters } from "vuex"
export default {
    data() {
        return {}
    },
    computed: {
        ...mapGetters([
            'getDeleteConnection',
            'getUpdateConnectionName'
        ])
    },
    methods: {
        async deleteConnection(connectionId){
            this.loading = true
            await store.dispatch('deleteConnection',connectionId)
            this.loading = false
            return this.getDeleteConnection
        },
        // async updateConnection(connectionId,userId){
            
        // },
        async updateConnectionName(connectionName,id){
            let payload = {
                name:connectionName,
                connectionId : id
            }
            this.loading = true
            await store.dispatch('updateConnectionName',payload)
            this.loading = false
            return this.getUpdateConnectionName
        }
    }
}