<template>
    <div class="banner-container">
        <el-row>
            <el-col>
                <div>
                    <div class="logo" style="padding: 30px;">
                        <img src="@/assets/eSignsLogo.svg" style="height: 25px" />
                    </div>
                    <div class="big-image" style="text-align: center; margin: 20px">
                        <img src="@/assets/LoginPageImage.svg" style="width: 300px" />
                    </div>
                    <div class="text">
                        <h2>
                            Establish automation workflow with all applications
                        </h2><br>
                        <p>
                            Streamline the automation of your preferred apps<br />
                            and devices in a fast and effortless manner
                        </p>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<style>
.banner-container {
    margin: 0px;
    padding: 0px;
    background-color: #1565d8;
    height: 100%;
    font-family: Inter, sans-serif;
    font-weight: bolder;
}

.text {
    text-align: center;
}

.text p {
    font-size: 1.25rem !important;
    color: white;
    text-align: center;
}

.text h2 {
    font-size: 1.25rem !important;
    color: white;
    text-align: center;

}</style>