import { createWebHistory, createRouter } from "vue-router";
import loginComponent from "../components/loginComponent.vue";
import signUp from "../components/signUp.vue";
import HomeView from "../views/HomeView.vue"
import createAutomation from "../components/connectorApp/CreateAutomation.vue";
import SettingsPage from "../components/SettingsPage.vue";
import profileBody from "../components/profileBody.vue";
import forgotPassword from "../components/forgotPassword.vue";
import forgotPasswordVerification from "../components/forgotPasswordVerification.vue";
import mapFields from '@/components/connectorApp/mapFields';
import ExistingConnection from '@/components/connectorApp/ExistingConnection';
import workflowsTable from '@/components/connectorApp/workflowsTable'
import ConfigureWorkflow from '@/components/connectorApp/ConfigureWorkflow';
import redirectUrl from '@/components/connectorApp/redirectUrlComponent'
import flowLogs from '@/components/connectorApp/flowLogs'
const routes = [
  {
    name: loginComponent,
    path: "/",
    component: loginComponent,
  },
  {
    name : redirectUrl,
    path : '/connector/callback',
    component: redirectUrl,

  },
  {
    name:ExistingConnection,
    path: "/connector/select-connection",
    component:ExistingConnection,
  },
  {
    name: signUp,
    path: "/signup",
    component: signUp,
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: forgotPassword,
  },
  {
    path: "/forgotPasswordVerification",
    name: "forgotPasswordVerification",
    component: forgotPasswordVerification,
  },
  {
    path: "/connector/workflow/:automationFlowId",
    name: "workflow",
    component: ConfigureWorkflow,
    meta: {
      title: "Workflow",
    },
  },
 
  //to get the topbar and sidebar(below)
  {
    name: HomeView,
    path: "/home-view",
    component: HomeView,
    children: [
      {
        path: "/SettingsPage",
        name: "SettingsPage",
        component: SettingsPage,
      },
      {
        path: "/profileBody",
        name: "profileBody",
        component: profileBody,
      },
      {
        path: "/connector/all-workflows",
        name: "workflowsTable",
        component: workflowsTable,
      },
      {
        path: "/connector/flowLogs",
        name: "flowLogs",
        component: flowLogs,
      },
    
      
      {
        path: "/connector",
        name: "create-automation",
        component: createAutomation,
        meta: {
          title: "Create Automation",
        },
      },
    ]
  },
  {
    path: "/connector/map-fields/:automationFlowId/:actionStepId",
    name: "mapFields",
    component: mapFields,
    props: (route) => ({
      automationFlowId: route.params.automationFlowId,
      actionStepId: route.params.actionStepId,
      query: route.query
    })
  }
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;
