<template>
  <section id="Signup__Page" v-loading="loading">
    <el-row>
      <el-col :xl="{ span: 11 }" :lg="{ span: 11 }" :md="{ span: 24 }" :sm="{ span: 24 }">
        <BannerComponent></BannerComponent>
      </el-col>
      <el-col :xl="{ span: 13 }" :lg="{ span: 13 }" :md="{ span: 24 }" :sm="{ span: 24 }">
        <div class="signUp__Flow">
          <div class="p-sm-5 p-3">
            <div class="nav-link fs-7 text-right" style="color: darkgray;text-align:right">
              Already have an account?
              <router-link to="/" class="fw-medium" style="
                  text-decoration: none;
                  cursor: pointer;
                  color: rgb(25, 137, 250);">Log in</router-link>
            </div>
            <div class="content mt-3" style="display:flex;justify-content:center;align-items:center">
              <div class="mt-4" style="width:60%">
                <h2 class="fs-3 text-dark pb-2" style="color:#212529">Create Account</h2>
                <hr>
                <el-form ref="userInfo" :model="userInfo" :rules="formRules" class="log-form" :label-position="'top'">
                  <el-form-item class="form-item" prop="email" label="Email">
                    <el-input class="el-input__inner" style="height: 60px; font-size: 12px" placeholder="Enter your Email"
                      v-model="userInfo.email"></el-input>
                  </el-form-item>
                  <div v-if="errors.emailError">
                    <p style="color:red;font-size: 12px">{{ errors.emailError }}</p>
                  </div>
                  <br>
                  <div class="d-flex" style="display: flex;flex-direction:row;justify-content:space-between">
                    <el-form-item prop="firstName" label="First Name" class="mr-1 form-item">
                      <el-input class="el-input__inner " style="height: 60px; font-size: 12px"
                        placeholder="Enter your first name" v-model="userInfo.firstName"></el-input>
                    </el-form-item>
                    <el-form-item label="Last Name" prop="lastName" class="form-item">
                      <el-input class="el-input__inner " style="height: 60px; font-size: 12px"
                        placeholder="Enter your last name" v-model="userInfo.lastName"></el-input>
                    </el-form-item>
                  </div>
                  <el-form-item prop="mobile" label="Mobile Number" class="form-item">
                    <el-input style="height: 60px; font-size: 12px" placeholder="Enter your mobile number"
                      v-model="userInfo.mobile"></el-input>
                  </el-form-item>
                  <el-form-item label="Password" prop="password" class="form-item">
                    <el-input type="password" autocomplete="off" style="height: 60px; font-size: 12px"
                      placeholder="Enter your password" v-model="userInfo.password"></el-input>
                  </el-form-item>
                  <el-form-item label="Confirm Password" prop="confirmpassword" class="form-item">
                    <el-input type="password" autocomplete="off" style="height: 60px; font-size: 12px"
                      placeholder="confirm your password" v-model="userInfo.confirmPassword" required></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" style="width:100%;background-color:hotpink;height:60px;font-size:18px;font-weight:bold"
                      @click="submitForm">Create
                      Account</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </section>
</template>
<script>
import store from '../store/store';
import { mapGetters } from "vuex";
import BannerComponent from "./BannerComponent.vue";
export default {
  data() {
    return {
      errors: {
        emailError: null
      },
      userInfo: {
        email: null,
        firstName: null,
        lastName: null,
        mobile: null,
        password: null,
        confirmPassword: null,
      },
      formRules: {
        email: [
          { required: true, message: 'Email is required', trigger: 'blur' },
          { type: 'email', message: 'Invalid email format', trigger: 'blur' },
        ],
        firstName: [
          { required: true, message: 'First name is required', trigger: 'blur' }
        ],
        lastName: [
          { required: true, message: 'Last name is required', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: 'Mobile number is required', trigger: 'blur' },
          { pattern: /^[0-9]*$/, message: 'Mobile must contain only numbers', trigger: 'blur' },
        ],
        password: [
          { required: true, message: 'Password is required', trigger: 'blur' },
          { min: 6, message: 'Password must be at least 10 characters', trigger: 'blur' },
          { validator: this.validatePassword, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: 'Confirm Password is required', trigger: 'blur' },
          {
            validator: this.validateConfirmPassword,
            trigger: 'blur',
          },
        ],
      },
      loading: false
    };
  },
  components: {
    BannerComponent
  },
  computed: {
    ...mapGetters(["getUserSignupResponse"])
  },
  methods: {
    validatePassword(rule, value, callback) {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasSpecialCharacter = /[!@#$%^&*()_+[\]{};':"\\|,.<>/?]+/.test(value);
      if (hasUpperCase && hasSpecialCharacter) {
        callback();
      } else {
        callback(new Error('Password must contain at least one capital letter and one special character'));
      }
    },
    validateConfirmPassword(rule, value, callback) {
      if (value === this.userInfo.password) {
        callback();
      } else {
        callback(new Error('Passwords do not match'));
      }
    },
    submitForm() {
      this.loading = true
      console.log("userInfo", this.userInfo)
      this.$refs.userInfo.validate(async (valid) => {
        if (valid) {
          await store.dispatch("userSignup", this.userInfo)
          if (this.getUserSignupResponse && this.getUserSignupResponse.code == 400 && this.getUserSignupResponse.status == false) {
            this.$notify({
              title: 'Error',
              message: this.getUserSignupResponse.message ? this.getUserSignupResponse.message : "Email Already Exists",
              type: 'error'
            });
          }
          else if (this.getUserSignupResponse.success == true) {
            this.$notify({
              title: 'Success',
              message: "Registration Successfull",
              type: 'success'
            });
            this.$router.push(this.$route.query.redirect || "/");
          }
        } else {
          console.log("Error In Filling Form")
        }
      });
      this.loading = false
    }
  },
};
</script>
<style scoped>
</style>
