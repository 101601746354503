<template>
  <div
    v-loading="loading"
    element-loading-spinner="el-loading-spinner"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <el-row :gutter="10">
      <el-col :lg="5" :md="4" :sm="24" :xs="24">
        <div style="float: left">
          <span style="color: #2c3e50; font-weight: 600"> Automation Logs </span>
        </div>
      </el-col>
      <el-col :lg="5" :md="6" :sm="24" :xs="24">
        <el-select
          v-model="selectedAutomation"
          size="medium"
          class="searchable-dropdown"
          @change="handleCurrentChange()"
        >
          <el-option
            v-for="(option, index) in allWorkflows"
            :key="index"
            :label="option.name"
            :value="option._id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :lg="5" :md="6" :sm="24" :xs="24">
        <el-select
          v-model="selectedStatus"
          size="medium"
          class="searchable-dropdown"
          @change="handleCurrentChange()"
        >
          <!-- <el-option label="All" value="all"></el-option> -->
          <el-option label="Failed" value="error"></el-option>
          <el-option label="Success" value="info"></el-option>
        </el-select>
      </el-col>
      <el-col :lg="9" :md="6" :sm="24" :xs="24">
        <el-date-picker
          v-model="dateRangeValue"
          type="daterange"
          unlink-panels
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          :shortcuts="shortcuts"
          size="medium"
          clearable
          @change="handleCurrentChange()"
        />
      </el-col>
    </el-row>

    <el-row style="margin-top: 3%">
      <el-col>
        <el-collapse v-model="activeNames" @change="getSpecifiedLogDetails">
          <el-collapse-item
            v-for="(item, index) in allLogs"
            :key="item._id"
            :title="`\u00A0\u00A0\u00A0\u00A0\ ${showLabel(
              selectedAutomation
            ).toUpperCase()}\u00A0\u00A0\u00A0\ | \u00A0\u00A0 ${getLocalTime(
              item.firstTimestamp
            )}`"
            :name="index"
            :class="{ 'alt-item-background': index % 2 !== 0 }"
            class="custom-collapse-item"
          >
            <el-table :data="item.documents" class="rounded-table">
              <el-table-column prop="status" label="Status">
                <template v-slot="{ row }">
                  <span class="status">
                    <div :style="getStatusStyle(row.statusCode)">
                      {{ row.statusCode }}
                    </div>
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="method" label="Method">
                <template v-slot="{ row }">
                  <span class="method">
                    <div :style="getMethodStyle(row.method)">
                      {{ row.method }}
                    </div>
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="Api Url">
                <template v-slot="{ row }">
                  <span class="apiUrl"> {{ truncateText(row.apiUrl) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="dateandtime" label="Date Time">
                <template v-slot="{ row }">
                  <span class="dateandtime">{{ getLocalTime(row.time) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="action" label="Action">
                <template v-slot="{ row }">
                  <span class="action">
                    <el-button
                      type="primary"
                      class="table_button"
                      @click="viewLogInfo(row)"
                      >View</el-button
                    >
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </el-collapse-item>
        </el-collapse>
      </el-col>
    </el-row>

    <div
      :class="{
        terminal: true,
        expanded: isExpanded,
        'larger-screen': isLargerScreen,
      }"
      :style="{ bottom: '20px', right: '20px' }"
    >
      <div class="terminal-header" @mousedown="handleMouseDown">
        Logs Terminal
        <span class="close-button" @click="closeTerminal">&times;</span>
        <span class="expand-icon" @click="toggleExpand">&#x25A2;</span>
      </div>
      <div
        class="terminal-content"
        v-show="isExpanded || isLargerScreen"
        ref="terminalContent"
      >
        <pre>
      {{ logData?.apiUrl }}
    </pre
        >
        <br /><br />
        <vue-json-pretty :data="logData?.payload" /><br /><br />
        <vue-json-pretty :data="logData?.response" />
      </div>
    </div>

    <el-pagination
      class="custom-pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[30, 60, 90, 120]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageTotal"
    />
  </div>
</template>
<script>
import store from "@/store/store";
import { mapGetters } from "vuex";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import filters from "@/mixins/filters";
export default {
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      isDragging: false,
      isExpanded: false,
      isLargerScreen: false,
      offsetX: 0,
      offsetY: 0,
      terminalTop: 20,
      terminalLeft: 20,
      originalTop: 20,
      originalLeft: 20,
      isCollapse: true,
      allWorkflows: [],
      showDropdown: false,
      searchString: "",
      AutomationTitleName: null,
      automationInfo: null,
      loading: true,
      newAutomationflowModal: false,
      selectedStatus: "info",
      hovered: false,
      isDependable: false,
      radio: "1",
      drawer: false,
      textarea2: "",
      rulesDrawer: {
        schedule: [
          {
            required: true,
            message: "schedule type is required",
            trigger: "blur",
          },
        ],
        date: [{ required: true, message: "date is required", trigger: "blur" }],
        weekly: [{ required: true, message: "date is required", trigger: "blur" }],
        monthly: [{ required: true, message: "date is required", trigger: "blur" }],
        yearly: [{ required: true, message: "date is required", trigger: "blur" }],
        once: [{ required: true, message: "date is required", trigger: "blur" }],
        startDateMonth: [
          { required: true, message: "date is required", trigger: "blur" },
        ],
      },
      shortcuts: [
        {
          text: "Last hour",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000);
            return [start, end];
          },
        },
        {
          text: "Last 12 hours",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 12);
            return [start, end];
          },
        },
        {
          text: "Last 24 hours",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            return [start, end];
          },
        },
        {
          text: "Today",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setHours(0, 0, 0, 0);
            start.setTime(start.getTime());
            return [start, end];
          },
        },
        {
          text: "Last 7 days",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 7 * 24 * 60 * 60 * 1000);
            return [start, end];
          },
        },
        {
          text: "Last 14 days",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(end.getTime() - 14 * 24 * 60 * 60 * 1000);
            return [start, end];
          },
        },
      ],
      dateRangeValue: "",
      allLogs: null,
      selectedAutomation: "",
      pageTotal: 0,
      currentPage: 1,
      pageSize: 15,
      logData: null,
      activeNames: [],
    };
  },
  computed: {
    ...mapGetters(["getAllFlowsByUserId", "getAllLogs", "getEachLog"]),
  },
  mounted() {
    this.fetchAllAutomations();
    document.addEventListener("mousemove", this.handleMouseMove);
    document.addEventListener("mouseup", this.handleMouseUp);
    // this.getAllLogs()
  },

  beforeUnmount() {
    document.removeEventListener("mousemove", this.handleMouseMove);
    document.removeEventListener("mouseup", this.handleMouseUp);
  },
  mixins: [filters],
  methods: {
    getStatusStyle(statusCode) {
      let style = {}; // Initialize an empty object for inline styles
      if (statusCode >= 200 && statusCode < 300) {
        // Success status code range (200-299)
        style.backgroundColor = "#5cb85c"; // Green background for success
        style.color = "white"; // White text color
      } else if (statusCode >= 400 && statusCode < 500) {
        // Client error status code range (400-499)
        style.backgroundColor = "#d9534f"; // Red background for client errors
        style.color = "white"; // White text color
      } else if (statusCode >= 500 && statusCode < 600) {
        // Server error status code range (500-599)
        style.backgroundColor = "#d9534f"; // Orange background for server errors
        style.color = "white"; // White text color
      }
      // Add padding of 10px to the left and right
      style.padding = "0 10px";
      style.borderRadius = "5px";
      return style;
    },
    getMethodStyle(method) {
      let style = {}; // Initialize an empty object for inline styles
      switch (method.toLowerCase()) {
        case "post":
          style.backgroundColor = "#5cb85c"; // Green background for GET
          style.color = "white";
          break;
        case "get":
          style.backgroundColor = "#5bc0de"; // Light blue background for POST
          style.color = "white";
          break;
        case "put":
          style.backgroundColor = "#f0ad4e"; // Orange background for PUT
          style.color = "white";
          break;
        case "patch":
          style.backgroundColor = "#FFA500 "; // Red background for PATCH
          style.color = "white";
          break;
        case "delete":
          style.backgroundColor = "#337ab7"; // Blue background for DELETE
          style.color = "white";
          break;
        default:
          style.backgroundColor = "#777"; // Gray background for other methods
          style.color = "white";
      }
      // Add padding of 10px to the left and right
      style.padding = "0 10px";
      style.borderRadius = "5px";
      // Capitalize the method
      return { ...style, textTransform: "capitalize" };
    },
    truncateText(text) {
      // Truncate text to show only 150 characters
      if (text && text.length > 15) {
        return text.slice(0, 30) + "...";
      }
      return text;
    },
    handleMouseDown(event) {
      if (event.target.classList.contains("terminal-header")) {
        this.isDragging = true;
        this.offsetX = event.clientX - this.terminalLeft;
        this.offsetY = event.clientY - this.terminalTop;
      }
    },
    handleMouseMove(event) {
      if (this.isDragging) {
        this.terminalLeft = event.clientX - this.offsetX;
        this.terminalTop = event.clientY - this.offsetY;
      }
    },
    handleMouseUp() {
      this.isDragging = false;
    },
    closeTerminal(event) {
      event.stopPropagation();
      this.isExpanded = false;
      this.isLargerScreen = false; // Close the larger screen if terminal is closed
    },
    toggleExpand() {
      if (this.isExpanded) {
        // If already expanded, toggle to full screen
        this.isExpanded = false;
        this.isLargerScreen = true;
        this.originalTop = this.terminalTop;
        this.originalLeft = this.terminalLeft;
        this.terminalTop = 0;
        this.terminalLeft = 0;
      } else {
        // If not expanded, toggle to normal expansion
        this.isExpanded = true;
        this.isLargerScreen = false;
        this.terminalTop = this.originalTop;
        this.terminalLeft = this.originalLeft;
      }
    },
    copyTerminalContent() {
      const terminalContent = this.$refs.terminalContent;
      const range = document.createRange();
      range.selectNode(terminalContent);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    },

    expandSidebar() {
      this.isCollapse = false;
    },
    collapseSidebar() {
      this.isCollapse = true;
    },
    rowStyle() {
      return {
        height: "80px",
        color: "0E1216",
        fontSize: "14px",
        fontWeight: 500,
      };
    },
    async getAllLogsByAutomation(params) {
      this.loading = true;
      await store.dispatch("fetchAllLogs", params);
      this.loading = false;
      this.allLogs = this.getAllLogs.data.allLogs;
      this.pageTotal = this.getAllLogs.data.total[0].totalCount;
    },
    async fetchAllAutomations() {
      const userData = localStorage.getItem("user-info");
      let userInfo = JSON.parse(userData);
      let params = {
        user_id: userInfo.user_id._id,
      };
      this.loading = true;
      await store.dispatch("fetchAllFlowsByUserId", params);
      this.loading = false;
      this.allWorkflows = this.getAllFlowsByUserId;
      this.selectedAutomation = this.allWorkflows[0]._id;
      this.handleCurrentChange();
    },
    async handleSizeChange(val) {
      this.pageSize = val;
      let params = {
        level: this.selectedStatus,
        automationId: this.selectedAutomation,
        startDate:
          this.dateRangeValue && this.dateRangeValue[0] ? this.dateRangeValue[0] : null,
        endDate:
          this.dateRangeValue && this.dateRangeValue[1] ? this.dateRangeValue[1] : null,
        pageValue: this.currentPage,
        limit: this.pageSize,
      };
      await this.getAllLogsByAutomation(params);
    },
    async handleCurrentChange(val) {
      this.currentPage = val;
      let params = {
        level: this.selectedStatus,
        automationId: this.selectedAutomation,
        startDate:
          this.dateRangeValue && this.dateRangeValue[0] ? this.dateRangeValue[0] : null,
        endDate:
          this.dateRangeValue && this.dateRangeValue[1] ? this.dateRangeValue[1] : null,
        pageValue: val,
        limit: this.pageSize,
      };
      await this.getAllLogsByAutomation(params);
    },
    async viewLogInfo(row) {
      try {
        let newJson = JSON.stringify(JSON.parse(row.payload), null, 2);
        this.logData = row;
        this.logData.payload = newJson;
      } catch (error) {
        this.logData = row;
      }
      this.isExpanded = true;
    },
    showLabel(value) {
      let macthedValue = this.allWorkflows.find((item) => item._id == value);
      return macthedValue.name;
    },
    async getItemTitle(value1, value2) {
      const label = await this.showLabel(value1);
      const time = this.getLocalTime(value2); // Assuming this.item is defined
      return `${label} ${time}`;
    },
    async getSpecifiedLogDetails(value) {
      let indexOfLog = value[value.length - 1];
      if (indexOfLog >= 0) {
        let logInfo = this.allLogs[indexOfLog];
        let payload = {
          key: logInfo.key,
          automationId: this.selectedAutomation,
          level: this.selectedStatus,
        };
        this.loading = true;
        await store.dispatch("getLogDetailsByKey", payload);
        this.loading = false;
        this.allLogs[indexOfLog].documents = this.getEachLog.data;
      } else {
        return;
      }
    },
  },
};
</script>
<style scoped>
.terminal {
  position: fixed;
  width: 300px;
  background-color: #333;
  color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 999;
  transition: top 0.2s ease-in-out, left 0.2s ease-in-out, bottom 0.2s ease-in-out,
    right 0.2s ease-in-out;
}

.expanded {
  width: 64%;
  height: 71%;
  top: 35%;
  left: 29%;
  transform: translate(-25%, -25%);
}

.larger-screen {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: none;
}

.terminal-header {
  background-color: #444;
  padding: 10px;
  cursor: grab;
}

.close-button {
  cursor: pointer;
  float: right;
  margin-top: -2px;
  margin-right: -2px;
  padding: 5px;
  color: #ccc;
}

.expand-icon {
  cursor: pointer;
  float: right;
  margin-right: 10px;
  padding: 5px;
}

.terminal-content {
  padding: 10px;
  height: calc(90% - 40px);
  /* Adjust the height as needed */
  overflow-y: auto;
  margin-bottom: 20px;
  /* Add 20px of margin to the bottom */
}

.custom-pagination {
  text-align: center;
  margin-top: 10px;
  margin-left: 25%;
}

.el-collapse-item__header.is-active {
  color: black !important;
  background-color: lightgrey !important;
  font-size: large !important;
}
</style>
