<template>
  <div v-loading="loading" element-loading-spinner="el-loading-spinner" element-loading-background="rgba(0, 0, 0, 0.1)">
    <el-dialog title="" v-model="iframe">
      <iframe v-if="iframe" :src="authUrl" width="100%" height="50%" frameborder="0" ref="authIframe"></iframe>
    </el-dialog>

    <el-container>
      <el-header class="header-configure-workflow">
        <el-row class="top-bar" allign="middle">
          <el-col :xl="1" :lg="4" :md="4" :sm="8" :xs="6" class="logo-container">
            <el-tooltip effect="dark" content="Go Back" placement="right">
              <el-button @click="goBack" class="button-back" type="text">
                <span aria-hidden="true" data-testid="iconContainer"
                  class="css-1brxyr-Icon--arrowBigLeft--animate--30x30--neutral100" style="background-color: white">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="30" width="30"
                    size="30" color="neutral100" name="arrowBigLeft">
                    <path fill="#2D2E2E" d="M7.14 13H20v-2H7.14l5.04-6H9.56l-5.87 7 5.87 7h2.62l-5.04-6Z"></path>
                    <path fill="#2D2E2E" d="M7.14 13H20v-2H7.14l5.04-6H9.56l-5.87 7 5.87 7h2.62l-5.04-6Z"></path>
                  </svg>
                </span>
              </el-button>
            </el-tooltip>
          </el-col>

          <el-col :xl="3" :lg="4" :md="4" :sm="8" :xs="9">
            <el-form class="form-title">
              <el-input v-model="selectedFlow" placeholder="Enter Entity Title" :class="getIsMobile ? 'nameSty' : 'input-type-one entity-title-input'
    " size="medium"></el-input>
              <p class="error" v-if="!selectedFlow"></p>
            </el-form>
          </el-col>
          <el-col :xl="1" :lg="4" :md="4" :sm="8" :xs="6" class="switch-container">
            <el-tooltip effect="dark" content="In-Activate / Activate" placement="right">
              <el-switch v-model="isActivate" active-color="#13ce66" inactive-color="#ff4949"
                @click="activateAutomationFlow">
              </el-switch>
            </el-tooltip>
          </el-col>
        </el-row>
      </el-header>
    </el-container>

    <el-col :xl="1" :lg="1" :md="1">
      <div class="fixed-sidebar">
        <Sidebar_workflow :class="{ 'sidebar-visible': isVisible }"></Sidebar_workflow>
      </div>
    </el-col>

    <el-row class="row-2" :xs="24" v-if="isDependable">
      <span class="notify-text">
        <el-icon>
          <InfoFilled />
        </el-icon>
        &nbsp; This automation works in a specific order. One action has to wait
        for another one to finish before it can happen.
      </span>
    </el-row>
    <el-row class="row-2" :xs="24" v-else>
      <span class="notify-text">
        <el-icon>
          <InfoFilled />
        </el-icon>
        &nbsp; In this automation flow each action is independent.
      </span>
    </el-row>
    <br />
    <!-- TRIGGER-FLOW -->
    <el-row class="row-2">
      <el-col :lg="1" :xs="24" class="circle-flow">
        <div class="wrapper">
          <div class="circle">
            <p class="circle-text">1</p>
          </div>
        </div>
        <div class="vertical-line-for-number-circle"></div>
      </el-col>

      <el-col :lg="14" class="for-border">
        <el-card class="card" shadow="never">
          <el-row shadow="always">
            <el-button circle disabled v-if="tAppLogo" class="card-logo-button">
              <img :src="tAppLogo" width="33" height="32" />
            </el-button>
            <el-button circle disabled v-else class="card-logo-button">
              <img src="@/assets/flowchart.png" width="33" height="32" />
            </el-button>
            <div style="display: flex; flex-direction: column">
              <el-row class="header-text"> 1.Trigger </el-row>
              <el-row class="header-p"> when a certain event occurs </el-row>
            </div>
          </el-row>
          <el-collapse class="card-content" v-model="activeName" accordion>
            <el-collapse-item style="border: none" name="1">
              <el-row></el-row>
              <el-row v-if="isTriggerAppSelected == false">
                <el-input class="button-search" v-model="searchApps" placeholder="Search App" size="large">
                  <template #prefix>
                    <el-icon class="el-input__icon">
                      <Search />
                    </el-icon>
                  </template>
                </el-input>
              </el-row>
              <!-- before clicking the app -->
              <el-row :gutter="20" v-if="isTriggerAppSelected == false" class="all-apps">
                <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="4" v-for="app in filteredApps" :key="app._id">
                  <el-button class="app-cards" shadow="always" @click="selectTriggerApp(app)">
                    <el-row :span="24" :gutter="15" style="
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                        cursor: pointer;
                      ">
                      <el-col :span="2">
                        <img :src="app.logo" width="25" />
                      </el-col>
                      &nbsp;
                      <el-col :span="22" class="image-circle-text">{{
    app.app_name
  }}</el-col>
                    </el-row>
                  </el-button>
                </el-col>
              </el-row>

              <!-- after selecting the app -->
              <el-row v-else>
                <el-card class="selected-app" width="100%" style="
                    box-shadow: 0 20px 10px -20px rgba(0, 0, 0, 0.45) inset;
                  ">
                  <el-button circle class="app-image" disabled>
                    <img :src="tAppLogo" width="33" height="32" /> </el-button>&nbsp; &nbsp;
                  <span style="float: left; font-size: 18px">
                    {{ selectedApp.app_name }}</span>
                  <el-button type="plain" class="change-button" @click="openAllApps" float="right">
                    Change
                  </el-button>
                </el-card>
                <!-- trigger-event -->
                <span style="color: red"> * </span>&nbsp;
                <span class="Event">Event</span>
                <el-select v-model="selectedTriggerAppEvent" placeholder="Choose an event" clearable filterable style="
                    width: 100%;
                    border: 1px solid rgb(183, 183, 185);
                    margin-top: 10px;
                    margin-bottom: 10px;
                  " class="select-dropdown" size="large">
                  <el-option v-for="event in selectedAppTriggerEvents" :key="event._id" :value="event._id"
                    :label="event.eventName" :disabled="event.enabled === 'false'" class="custom-option">
                    <span>
                      <span>{{ event.eventName }}-{{
    event.eventDescription
  }}</span>
                    </span>
                  </el-option>
                </el-select>

                <!-- after the event selection -->
                <el-row v-if="webhookData">
                  <el-divider direction="horizontal" border-style="dashed" class="divider" />
                  <el-input shadow="never" class="webhookUrl-Holder" v-model="webhookData.webhookUrl"
                    disabled></el-input>
                  <el-button type="primary" class="copyLink-button" @click="copyTheTextInTheInputBox">
                    <el-icon>
                      <CopyDocument />
                    </el-icon>
                    <span v-if="isLinkCopied">Copied</span>
                    <span v-else>Copy Link</span>
                  </el-button>
                  <el-col :span="24">
                    <ul class="list-items-webhook" align="left" style="color: #606366">
                      <li v-for="(step, index) in webhookData.steps" :key="index" style="margin-top: 5px">
                        <span v-html="convertUrlsToLinks(step)"></span>
                      </li>
                    </ul>
                  </el-col>
                  <el-button type="plain" class="capture-webhook-button">
                    Capture Webhook Response
                  </el-button>
                  <el-card shadow="never" class="select-event"
                    v-if="this.webhookData && this.webhookData.additionalInfo" style="
                      color: #4c4b4e;
                      border-left: 3px solid #409eff;
                      background-color: #edf5fa;
                      display: flex;
                      margin-bottom: 10px;
                      margin-top: 1em;
                    ">
                    <el-icon>
                      <InfoFilled />
                    </el-icon>&nbsp;
                    <span style="text-align: left; flex: 1">
                      {{ webhookData.additionalInfo }}
                    </span>
                  </el-card>
                </el-row>
                <el-button shadow="always" class="signin-to-trigger-app" size="large"
                  v-if="isTriggerAppConnected == false" type="primary" @click="updateTriggerAppInfo" style="
                    font-size: 17px;
                    height: 48px;
                    border: none;
                    background-color: #f754a2;
                    margin-top: 10px;
                    margin-left: auto;
                  ">
                  <span>Connect {{ selectedApp.app_name }}</span>
                </el-button>
                <el-button :type="connectedButtonType" v-if="isTriggerAppConnected && selectedTriggerAppEvent"
                  class="signin-to-trigger-app" style="
                    width: 100%;
                    height: 48px;
                    font-size: 17px;
                    margin-top: 10px;
                    margin-bottom: 5px;
                    margin-left: auto;
                  " id="connectedButton">
                  Connected
                </el-button>
                <el-form :model="fieldsObject.formModel" label-position="top" style="width: 100%"
                  v-if="isTriggerAppConnected && fieldsObject">
                  <el-form-item v-for="(parentField, parentIndex) in fieldsObject.labels" :key="parentIndex"
                    :label="parentField.label" :rules="parentField.formRules" :prop="parentField.key">
                    <el-input v-if="parentField.type === 'input'" v-model="parentField.model"
                      :placeholder="parentField.label"
                      style="border: 1px solid rgb(183, 183, 185); width: 100%; margin-bottom: 20px;" size="large"
                      required />
                    <el-select v-if="parentField.type === 'select'" v-model="parentField.model"
                      placeholder="Select Field" class="select-dropdown"
                      style="border: 1px solid rgb(183, 183, 185); width: 100%; margin-bottom: 20px;" size="large"
                      @change="getNestedFields(parentField, parentIndex)" required>
                      <el-option v-for="option in parentField.value" :key="option.id" :label="option.name"
                        :value="option.id" />
                    </el-select>

                    <template v-for="(childField, childIndex) in parentField.children" :key="childIndex">
                      <el-form-item v-if="childField.type === 'input'" :label="childField.label"
                        :rules="childField.formRules" style="width: 100%">
                        <el-input v-model="childField.model" :placeholder="childField.label"
                          style="border: 1px solid rgb(183, 183, 185); width: 100%;" size="large" required />
                      </el-form-item>
                      <el-form-item v-if="childField.type === 'select'" :label="childField.label"
                        :rules="childField.formRules" style="width: 100%">
                        <el-select v-model="childField.model" placeholder="Select Field" class="select-dropdown"
                          style="border: 1px solid rgb(183, 183, 185); width: 100%;" size="large" @change="getChildFields
    (childField, childIndex, parentIndex)">
                          <el-option v-for="option in childField.value" :key="option.id" :label="option.name"
                            :value="option.id" />
                        </el-select>
                      </el-form-item>
                    </template>

                  </el-form-item>
                </el-form>
                <el-row>
                  <el-button @click="saveTriggerEventData" type="primary" size="large" class="signin-to-trigger-app"
                    style="
                      font-size: 17px;
                      width: 100%;
                      float: right;
                      margin-left: auto;
                    " v-if="selectedTriggerAppEvent && isTriggerAppConnected">Save</el-button>
                </el-row>
                <el-drawer v-model="isTriggerAppDrawerOpened" custom-class="custom-dialog" :append-to-body="true"
                  :destroy-on-close="true" @close="onDrawerClose" size="40%" style="background-color: #f8f9fa">
                  <ExistingConnection :selectedFlow="selectedFlow" :selectedTriggerAppEvent="selectedTriggerAppEvent"
                    :selectedApp="selectedApp" :isTriggerAppConnected="isTriggerAppConnected"
                    :isTriggerAppDrawerOpened="isTriggerAppDrawerOpened" :selectEntity="selectEntity"
                    :drawerLogo="tAppLogo" :triggerAppInfo="getAutomationFlowById" @iframeEvent="actiavetIframe">
                  </ExistingConnection>
                </el-drawer>
                <el-drawer v-model="actionDailogExist" custom-class="custom-dialog" :append-to-body="true"
                  :destroy-on-close="true" size="40%" style="background-color: #f8f9fa">
                  <actionExistingConnection :actionLogo="actionLogo" :aLogo="aLogo" :options="allWorkflows"
                    :actionEventsModel="actionEventsModel" :actionSelectApp="actionSelectApp"
                    :actionEventConnected="actionEventConnected" :selectActionEntity="selectActionEntity"
                    :actionDailogExist="actionDailogExist" :selectedFlow="selectedFlow" :propsObject="actionData"
                    :triggerAppInfo="getAutomationFlowById" ref="actionExistingConnectionRef"
                    @getTokensExistedAction="getTokensExistedAction" v-on:fetchExisistingConnectionsAction="
    actionDailogExist = true
    ">
                  </actionExistingConnection>
                </el-drawer>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-card>
      </el-col>
      <el-col>
        <div class="vertical-line-container">
          <div class="arrow-down">
            <img src="@/assets/vertical-line.png" height="30" width="40" />
          </div>
        </div>

        <div class="button-container">
          <el-tooltip class="item" content="Add action step" placement="top-start">
            <el-button circle @click="addActionStep" shadow="always" size="large" style="border: 1px solid #409eff">
              <el-icon :style="{ color: '#409EFF' }" size="large">
                <Plus />
              </el-icon>
            </el-button>
          </el-tooltip>
        </div>
        <div class="arrow-down">
          <img src="@/assets/arrow-down.png" height="30" width="40" />
        </div>
      </el-col>
    </el-row>

    <!-- ACTION-FLOW -->
    <el-row class="row-2" v-for="(item, index) in actionSteps" :key="index">
      <el-col :lg="1" class="circle-flow">
        <div class="wrapper">
          <div class="circle">
            <p class="circle-text">{{ getCircleNumber(index) }}</p>
          </div>
        </div>
        <div class="vertical-line-for-number-circle"></div>
      </el-col>
      <el-col :lg="14" class="for-border">
        <el-card class="card" shadow="never">
          <el-dropdown trigger="click" style="float: right">
            <el-icon class="more-icon">
              <More />
            </el-icon>

            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="editMapping(item)">
                  <el-icon>
                    <Edit />
                  </el-icon>
                  Edit Mapping</el-dropdown-item>
                <el-dropdown-item @click="viewMappingScreen(item)">
                  <el-icon>
                    <View />
                  </el-icon>
                  View Mapping</el-dropdown-item>
                <el-dropdown-item @click="scheduleAction(item)">
                  <el-icon>
                    <Clock />
                  </el-icon>
                  Schedule this Action</el-dropdown-item>
                <el-dropdown-item @click="deleteActionStep(item)">
                  <el-icon>
                    <Delete />
                  </el-icon>
                  Delete</el-dropdown-item>
                <el-dropdown-item @click="viewChatgptResponse(item)" v-if="app_name === 'ChatGpt'">
                  <el-icon>
                    <View />
                  </el-icon>
                  <span>View ChatGpt Response</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-dialog title="Schedule your action" v-model="scheduleActionModel" draggable v-loading="modalLoading"
            :element-loading-text="loadingText" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)" :close-on-click-modal="false" :close-on-press-escape="false"
            :before-close="destroyCreateModal" :custom-class="['light-theme-dialog']">
            <div>
              <el-date-picker v-model="scheduleModel" type="datetime" placeholder="Select date and time">
              </el-date-picker>
              <el-button type="primary" @click="saveSchedule(item)" style="float: right">Save</el-button>
            </div>
          </el-dialog>
          <el-dialog title="Your Existing Mapping Record" v-model="openMappingDialog" draggable v-loading="modalLoading"
            :element-loading-text="loadingText" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)" :close-on-click-modal="false" :close-on-press-escape="false"
            :before-close="destroyCreateModal">
            <div>
              <MappingView :automationInfo="getAutomationFlowById" :actionStep="actionStepInfo">
              </MappingView>
            </div>
          </el-dialog>
          <el-dialog title="ChatGpt Response" v-model="chatgptResponse" :close-on-click-modal="false"
            :close-on-press-escape="false" :before-close="destroyCreateModal" v-loading="modalLoading" draggable>
            chatgpt Response
          </el-dialog>
          <el-row>
            <el-button circle disabled v-if="item.actionAppLogo" class="card-logo-button">
              <img :src="item.actionAppLogo" width="33" height="32" />
            </el-button>
            <el-button circle v-else class="card-logo-button">
              <img src="@/assets/flowchart.png" width="33" height="32" />
            </el-button>
            <div style="display: flex; flex-direction: column">
              <el-row class="header-text"> {{ index + 1 }}.Action </el-row>
              <el-row class="header-p"> Do This </el-row>
            </div>
          </el-row>
          <el-collapse class="card-content" v-model="activeName" accordion>
            <el-collapse-item style="border: none" name="1">
              <el-row v-if="item.multiSelectAction == false">
                <el-input class="button-search" v-model="searchActionApps" placeholder="Search App" size="large">
                  <template #prefix>
                    <el-icon class="el-input__icon">
                      <Search />
                    </el-icon>
                  </template>
                </el-input>
              </el-row>
              <div v-if="item.multiActionSelect">
                <el-row :gutter="16" style="margin-top: 1em">
                  <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="4" v-for="app in filteredActionApps" :key="app._id">
                    <el-button class="app-cards pointer-on-hover" shadow="always" @click="selectActionApp(item, app)">
                      <div class="action-apps">
                        <img :src="app.logo" width="25" />
                        &nbsp;<span class="image-circle-text">{{
    app.app_name
  }}</span>
                      </div>
                    </el-button>
                  </el-col>
                </el-row>
              </div>

              <!-- after selecting the action app -->
              <el-row v-else>
                <el-card class="selected-app" width="100%" v-if="item.multiSelectAction" style="
                    box-shadow: 0 20px 10px -20px rgba(0, 0, 0, 0.45) inset;
                  ">
                  <el-button circle class="app-image" disabled>
                    <img :src="item.actionAppLogo" width="33" height="32" /> </el-button>&nbsp; &nbsp;
                  <span style="float: left; font-size: 18px">{{
    item.appInfo.app_name
  }}</span>
                  <el-button type="plain" class="change-button" @click="openAllAppAction(item)" float="right">
                    Change
                  </el-button>
                  {{ app_name }}
                </el-card>
                <span style="color: red" v-if="item.multiActionSelect == false">
                  *
                </span>
                &nbsp;
                <span class="Event" v-if="item.multiActionSelect == false">Event</span>
                <el-select v-model="item.selectedActionEvent" placeholder="Add a record" clearable filterable style="
                    width: 100%;
                    border: 1px solid rgb(183, 183, 185);
                    margin-bottom: 10px;
                  " class="select-dropdown" size="large" @change="checkEvents(item)">
                  <el-option v-for="(event, index) in item.multipleActionsEvents" :key="index" :value="event._id"
                    :label="event.eventName" :disabled="event.enabled === 'false'" class="custom-option">
                    <span>
                      <span>{{ event.eventName }}-{{
    event.eventDescription
  }}</span>
                    </span>
                  </el-option>
                </el-select>
                <span style="color: red" v-if="item.appInfo.app_name === 'ChatGpt'">
                  * </span>&nbsp;
                <span class="Event" v-if="item.appInfo.app_name === 'ChatGpt'">Prompt</span>
                <el-input v-if="item.appInfo.app_name === 'ChatGpt'" v-model="chatgptPrompt"
                  placeholder="Enter Prompt, E.g. Draft an email for taking a leave tomorrow." size="large" style="
                    border: 1px solid rgb(183, 183, 185);
                    border-radius: 3px;
                    margin-bottom: 10px;
                  ">
                </el-input>

                <el-col class="label-col" :xs="24" :sm="24" :md="11" :lg="11" :xl="12" v-if="item.appInfo.app_name === 'ChatGpt' &&
    item.multipleActionEventConnected
    ">
                  <span class="Event" style="float: left">Label</span>
                  <el-input v-model="formInline.label" placeholder="Prompt Status" clearable disabled size="large"
                    style="
                      width: 100%;
                      border: 1px solid rgb(183, 183, 185);
                      border-radius: 3px;
                    " />
                </el-col>
                <el-col :xs="24" :sm="24" :md="11" :lg="11" :xl="12" v-if="item.appInfo.app_name === 'ChatGpt' &&
    item.multipleActionEventConnected
    ">
                  <span class="Event" style="float: left">Value</span>
                  <el-input v-model="formInline.value" type="textarea" size="small" clearable style="
                      width: 100%;
                      border: 1px solid rgb(183, 183, 185);
                      border-radius: 3px;
                    ">
                  </el-input>
                </el-col>
                <el-button shadow="always" size="large" type="primary" :loading="submitLoading" v-if="item.selectedActionEvent &&
    item.multipleActionEventConnected == false
    " style="
                    width: 100%;
                    font-size: 17px;
                    height: 48px;
                    border: none;
                    background-color: #f754a2;
                    margin-bottom: 10px;
                    margin-top: 10px;
                  " @click="updateActionAppInfo(item)" class="connectButton-action">
                  <span>Connect {{ item.appInfo.app_name }}</span>
                </el-button>
                <el-button type="primary" v-else-if="item.multipleActionEventConnected &&
    item.buttonType == false
    " style="
                    width: 100%;
                    height: 48px;
                    align: left;
                    border-radius: 5px;
                    font-size: 17px;
                    margin-bottom: 10px;
                    margin-top: 10px;
                  ">Connected</el-button>
                <el-form :model="item.fieldsObject.formModel" :rules="item.fieldsObject.formRules" label-position="top"
                  style="width: 100%" v-if="item.multipleActionEventConnected && item.fieldsObject">
                  <el-form-item v-for="(parentField, parentIndex) in item.fieldsObject
    .labels" :key="parentIndex" :label="parentField.label" :rules="parentField.formRules">
                    <template v-if="parentField.type === 'select'">
                      <div class="el-form-item__content">
                        <el-select v-model="parentField.model" placeholder="Select Field" class="select-dropdown"
                          style="border: 1px solid rgb(183, 183, 185); flex: 9" size="large" @change="
    getActionNestedFields(
      parentField,
      parentIndex,
      item
    )
    ">
                          <el-option v-for="option in parentField.value" :key="option.id" :label="option.name"
                            :value="option.id" />
                        </el-select>
                        &nbsp;
                        <span title="Refresh to fetch newly added options">
                          <button aria-disabled="false" type="button"
                            class="el-button el-button--primary el-button-refresh" @click="
    refreshAction(parentField, parentIndex, item)
    ">
                            <i class="el-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100"
                                viewBox="0 0 30 30">
                                <path
                                  d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z">
                                </path>
                              </svg>
                            </i>
                          </button>
                        </span>
                      </div>

                      <el-form-item v-for="(childField, childIndex) in parentField.children" :key="childIndex"
                        :label="childField.label" :rules="childField.formRules">
                        <template v-if="childField.type === 'select'">
                          <el-select v-model="childField.model" placeholder="Select Field" class="select-dropdown"
                            style="
                              border: 1px solid rgb(183, 183, 185);
                              width: 100%;
                            " size="large" @change="
    getActionChildFields(
      parentField,
      childIndex,
      parentIndex,
      item
    )
    ">
                            <el-option v-for="option in childField.value" :key="option.id" :label="option.name"
                              :value="option.id" />
                          </el-select>
                        </template>
                      </el-form-item>
                    </template>
                  </el-form-item>
                </el-form>
                <div v-if="item.selectedActionEvent &&
    item.multipleActionEventConnected
    ">
                  <el-button @click="saveActionEvent(item)" v-if="shouldShowSaveButton(item)" type="primary" style="
                      height: 48px;
                      display: flex;
                      align: left;
                      font-size: 17px;
                      margin-top: 10px;
                    ">Save & Map Fields</el-button>
                  <el-button @click="saveDeleteActionEvent(item)" v-else type="primary" size="large" style="
                      font-size: 17px;
                      width: 100%;
                      float: left;
                      margin-left: auto;
                    ">Save</el-button>
                </div>
                <!-- <div
                  v-if="
                    item.selectedActionEvent &&
                    item.multipleActionEventConnected &&
                    item.entityActionName &&
                    item.appInfo.app_name == 'eSigns'
                  "
                >
                  <el-button
                    @click="saveActionEvent(item)"
                    v-if="shouldShowSaveButton(item)"
                    type="primary"
                    style="
                      height: 48px;
                      display: flex;
                      align: left;
                      font-size: 17px;
                      margin-top: 10px;
                    "
                    >Save & Map Fields</el-button
                  >
                </div> -->
                <div>
                  <el-dialog v-model="saveTrigger" width="30%" style="border-radius: 10px">
                    <div>
                      <h1 style="color: darkseagreen">
                        Data Saved Successfully
                      </h1>
                    </div>
                    <div>
                      <el-button type="success" round @click="saveTrigger = false">OK</el-button>
                    </div>
                  </el-dialog>
                </div>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-card>
      </el-col>
      <el-col>
        <div class="vertical-line-container">
          <div class="arrow-down">
            <img src="@/assets/vertical-line.png" height="30" width="40" />
          </div>
        </div>
        <div class="button-container">
          <el-tooltip class="item" effect="dark" content="Add action step" placement="top-start">
            <el-button circle @click="addActionStep" size="large" style="border: 1px solid #409eff">
              <el-icon :style="{ color: '#409EFF' }" size="large">
                <Plus />
              </el-icon>
            </el-button>
          </el-tooltip>
        </div>
        <div class="arrow-down">
          <img src="@/assets/arrow-down.png" height="30" width="40" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import store from "../../store/store";
import ExistingConnection from "./ExistingConnection.vue";
import { mapGetters } from "vuex";
import actionExistingConnection from "@/components/connectorApp/actionExistingConnection";
import MappingView from "@/components/connectorApp/MappingView";
import { ref } from "vue";
import Sidebar_workflow from "@/layouts/Sidebar_workflow.vue";
export default {
  name: "workflows-addEditWorkflow",
  components: {
    ExistingConnection,
    actionExistingConnection,
    MappingView,
    Sidebar_workflow,
  },

  data() {
    return {
      index: 0,
      selectedSpreadsheet: null,
      app_recognization_key: null,
      selectChannels: false,
      selectUsers: false,
      globalAutomationId: null,
      activeName: "1",
      openMappingDialog: false,
      selectedAppTriggerEvents: [],
      connectedButtonType: "primary",
      searchApps: "",
      searchActionApps: "",
      allWorkflows: [],
      isTriggerAppSelected: false,
      ActionSelect: true,
      actionsEvents: [],
      selectedTriggerAppEvent: null,
      actionEventsModel: "",
      selectedApp: null,
      actionSelectApp: null,
      isTriggerAppConnected: false,
      actionEventConnected: false,
      saveTrigger: false,
      saveAction: false,
      allApps: [],
      actionLogo: "",
      tAppLogo: null,
      aLogo: null,
      actionAppLogo: null,
      loading: false,
      workflowId: "",
      selectEntity: false,
      selectSheet: false,
      selectSheetOfSs: false,
      entityName: null,
      userEntityList: null,
      trigger_app_connection_id: null,
      selectActionEntity: false,
      userActionEntityList: null,
      entityActionName: "",
      toBeProcessed: true,
      isTriggerAppDrawerOpened: ref(false),
      actionDailogExist: ref(false),
      actionSteps: [],
      hardCheck: true,
      multipleActionEventConnected: false,
      actionData: null,
      isActivate: true,
      actionStepInfo: null,
      trigger_app_unique_name: null,
      quickbooksUrl: `${process.env.API_URL}/executeAutomaionFlow/send-quickbooks-webhook-data`,
      isLinkCopied: false,
      isDependable: false,
      scheduleActionModel: false,
      chatgptResponse: false,
      scheduleModel: null,
      authUrl: null,
      miniTab: null,
      iframe: false,
      queryData: null,
      chatgptPrompt: "",
      formInline: {
        label: null,
        value: null,
      },
      webhookUrl: "",
      webhookData: null,
      userActionSpreadsheetsList: null,
      userCorporationsList: null,
      userFoldersList: null,
      SheetsList: [],
      selectedActionSpreadsheet: null,
      selectedFolder: null,
      selectedChannel: null,
      selectedUser: null,
      CorporationList: null,
      selectedChat: null,
      selectActionSheet: false,
      selectCorporation: false,
      selectFolder: false,
      selectedSheet: null,
      searchEvent: "",
      allAppTriggerEvents: [],
      userchatsList: null,
      userChannelsList: null,
      usersList: null,
      faceBookPagesList: null,
      selectedFacebookPage: null,
      selectedYChannelId: null,
      youtubeChannelsList: null,
      selectedWorkbook: null,
      workbooksList: null,
      fieldsObject: null,
      formModel: {},
      formRules: {},
    };
  },
  computed: {
    filteredApps() {
      if (!this.searchApps) return this.allApps;
      const searchTerm = this.searchApps.toLowerCase();
      return this.allApps.filter((app) =>
        app.app_name.toLowerCase().includes(searchTerm)
      );
    },
    filteredActionApps() {
      if (!this.searchActionApps) return this.getActionApps.data;
      const query = this.searchActionApps.toLowerCase();
      return this.getActionApps.data.filter((app) =>
        app.app_name.toLowerCase().includes(query)
      );
    },
    ...mapGetters([
      "getAutomationFlowById",
      "getAllFlowsByUserId",
      "getTriggerApps",
      "getActionApps",
      "getUpdateTriggerAppData",
      "getTokens",
      // "getEntityList",
      "getEsignsTokens",
      "getUpdateActionAppData",
      "getisFlowActivated",
      "getOutlookTokensInfo",
      "getLatestAction",
      "getAllActions",
      "getUpdateActionAppDataConnection",
      "getActionSchedule",
      "getDeleteActionFlow",
      // "getOauthTokens",
      "getWebhookInstructions",
      // "getGooglesheetsdata",
      // "getSheetsdata",
      // "getAllCorporationsList",
      "getFbPageWebhookResponse",
      // "getAllOneDriveFolders",
      // "getAllTeamsChats",
      // "getAllSlackChannels",
      // "getAllSlackUsers",
      // "getFaceBookPages",
      "getAppFieldsByName",
      "getNestedAppFields",
      "getChildAppFields",
      "getRefreshedAppFields",
    ]),
  },
  created() {
    this.emitter.on("setConnection", (data) => {
      this.bindConnection(data);
    });
    this.emitter.on("setActionConnection", (data) => {
      this.bindActionConnection(data);
    });
  },
  async mounted() {
    await this.fetchAutomationFlowById();
    await this.getAllTriggerAppsList();
    await this.setPropertiesOfTriggerApp();
    await this.showActionSteps();
  },
  methods: {
    async onDrawerClose() {
      this.isTriggerAppDrawerOpened = false;
    },
    convertUrlsToLinks(text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const urlsInText = text.match(urlRegex) || [];
      this.new = urlsInText;
      console.log("URLs in Text:", urlsInText);
      const textWithoutUrls = text.replace(urlRegex, "");
      return textWithoutUrls.replace(/<([^>]+)>/g, (_, content) =>
        this.getClickHereLink(content)
      );
    },
    getClickHereLink(content) {
      return `<a href="${this.new
        }" style="color: #409eff; cursor: pointer;" target="_blank">${content ? " " + content : ""
        }</a>`;
    },
    goBack() {
      this.$confirm("Are you sure to Exit?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "warning",
      })
        .then(async () => {
          this.$router.replace({
            path: `/connector/all-workflows`,
          });
        })
        .catch(() => {
          this.$message({
            title: "Warning",
            message: "Exit canceled",
            type: "warning",
          });
        });
    },
    async fetchAutomationFlowById() {
      this.globalAutomationId = this.$route.params.automationFlowId;
      this.loading = true;
      await store.dispatch("fetchAutomationFlowById", {
        id: this.globalAutomationId,
      });
      this.automationInfo = this.getAutomationFlowById.data;
      this.loading = false;
      if (
        this.getAutomationFlowById &&
        this.getAutomationFlowById.data &&
        this.getAutomationFlowById.data._id
      ) {
        this.isActivate = this.getAutomationFlowById.data.isActivated;
        this.isDependable = this.getAutomationFlowById.data.isDependable;
        this.selectedFlow = this.getAutomationFlowById.data.name;
      } else {
        console.log("error in fetching workflow");
      }
    },
    async fetchAllAutomationflows() {
      let params = {
        user_id: this.getAutomationFlowById.data.user_id,
      };
      this.loading = true;
      await store.dispatch("fetchAllFlowsByUserId", params);
      this.loading = false;
      if (this.getAllFlowsByUserId) {
        this.allWorkflows = this.getAllFlowsByUserId;
        let flowFound = this.getAllFlowsByUserId.find((flow) => {
          if (
            flow &&
            flow._id &&
            this.getAutomationFlowById.data._id === flow._id
          ) {
            return true;
          } else {
            return false;
          }
        });
        if (flowFound) {
          this.selectedFlow = flowFound.name;
        }
      }
    },
    async getAllTriggerAppsList() {
      let eventType = "Trigger";
      this.loading = true;
      await store.dispatch("fetchAllTriggerApps", eventType);
      this.loading = false;
      if (this.getTriggerApps && this.getTriggerApps.data) {
        this.allApps = this.getTriggerApps.data;
      }
    },
    async selectTriggerApp(app) {
      this.selectedApp = app;
      this.tAppLogo = app.logo;
      this.isTriggerAppSelected = true;
      if (app && app.trigger_events) {
        this.selectedAppTriggerEvents = app.trigger_events;
      }
      await this.fetchWebhookInstructions(app.app_name);
    },
    async updateTriggerAppInfo() {
      this.isTriggerAppDrawerOpened = true;
      if (!this.selectedTriggerAppEvent) {
        this.$message.error("Please select an event before connecting.");
        return;
      }
      let selectedEventFound = await this.selectedAppTriggerEvents.find(
        (event) => event._id === this.selectedTriggerAppEvent
      );
      let params = {
        appId: this.selectedApp._id,
        app_connection_id: null,
        eventName: selectedEventFound.eventName,
        eventDescription: selectedEventFound.eventDescription,
        eventType: selectedEventFound.eventType,
        entityName: selectedEventFound.entity,
        operation: selectedEventFound.operation,
        automationId: this.automationInfo._id,
        app_name: this.selectedApp.app_name,
        event_id: selectedEventFound._id,
      };
      await store.dispatch("updateAutomationFlowWithTrigger", params);
      if (
        this.getUpdateTriggerAppData &&
        this.getUpdateTriggerAppData.success
      ) {
        this.automationInfo = this.getUpdateTriggerAppData.data;
        this.isTriggerAppDrawerOpened = true;
      } else {
        console.log(
          "automation flow not updated with the trigger app information"
        );
      }
    },
    async setPropertiesOfTriggerApp() {
      let appFound;
      if (this.getTriggerApps.data) {
        appFound = this.getTriggerApps.data.find((app) => {
          if (
            app &&
            app._id &&
            this.getAutomationFlowById.data &&
            this.getAutomationFlowById.data.app_id === app._id
          ) {
            return app;
          }
        });
        if (appFound) {
          this.isTriggerAppSelected = true;
          await this.selectTriggerApp(appFound);
          let triggerAppSelectedEvent = this.selectedAppTriggerEvents.find(
            (event) => {
              if (
                event &&
                this.getAutomationFlowById.data &&
                event.entity === this.getAutomationFlowById.data.entity_name &&
                event.operation === this.getAutomationFlowById.data.operation &&
                event.eventName == this.getAutomationFlowById.data.event_name
              ) {
                return true;
              }
            }
          );
          if (triggerAppSelectedEvent) {
            this.selectedTriggerAppEvent = triggerAppSelectedEvent._id;
          }
          this.isTriggerAppConnected = this.getAutomationFlowById.data
            .app_connection_id
            ? true
            : false;
          this.trigger_app_connection_id = this.getAutomationFlowById.data
            .app_connection_id
            ? this.getAutomationFlowById.data.app_connection_id
            : null;
          this.connectedButtonType = this.getAutomationFlowById.data
            .app_connection_id
            ? "primary"
            : "primary";
          if (this.isTriggerAppConnected) {
            this.fieldsObject = this.getAutomationFlowById.data.requiredIds;
          }
        }
      }
      return appFound;
    },
    async saveTriggerEventData() {
      let params = {
        flowId: this.getAutomationFlowById.data._id,
        app_connection_id: this.trigger_app_connection_id,
        requiredIds: this.fieldsObject,
      };
      if (this.selectedApp.app_name == "QuickBooks") {
        params = {
          flowId: this.getAutomationFlowById.data._id,
          app_connection_id: this.trigger_app_connection_id,
          requiredIds: this.fieldsObject,
          app_recognization_key: this.app_recognization_key
        };
      }
      this.loading = true;
      await store.dispatch("updateTriggerAppInfoInFlow", params);
      this.loading = false;
      if (
        this.getUpdateTriggerAppData &&
        this.getUpdateTriggerAppData.success
      ) {
        this.connectedButtonType = "primary";
        this.$notify.success({
          title: "Success",
          message: "Data saved successfully",
        });
        let webhookBody = {
          appName: this.selectedApp.app_name,
          automationId: this.automationInfo._id,
          isCron: false,
        };
        this.loading = true;
        await store.dispatch("registerWebhookInThirdParty", webhookBody);
        this.loading = false;
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in saving data",
        });
      }
    },
    async getAllActionAppsList() {
      let eventType = "Action";
      this.loading = true;
      await store.dispatch("fetchAllActionApps", eventType);
      this.loading = false;
    },
    async addActionStep() {
      let automationId = this.getAutomationFlowById.data._id;
      await store.dispatch("fetchLatestAction", automationId);
      await this.fetchAutomationFlowById();
      if (
        this.getLatestAction.status &&
        this.getLatestAction.data &&
        this.getLatestAction.data.app_connection_id
      ) {
        await this.getAllActionAppsList();
        let newActionStep = {
          id: this.actionSteps.length,
          name: "Action Step" + this.id,
          selectedActionEvent: null,
          multiSelectAction: false,
          multiActionSelect: true,
          multipleActionEventConnected: false,
          multipletoBeProcessed: false,
          multipleActionConnectionId: null,
          selectActionEntity: false,
          entityActionName: null,
          userActionEntityList: null,
          userActionSpreadsheetsList: null,
          userCorporationsList: null,
          multiActionInput: "",
          buttonType: true,
        };
        this.actionSteps.push(newActionStep);
        this.$notify({
          title: "Success",
          message: "Action step added successfully",
          type: "success",
        });
      } else if (this.getAutomationFlowById.data.app_connection_id) {
        await this.getAllActionAppsList();
        let newActionStep = {
          id: this.actionSteps.length,
          name: "Action Step" + this.id,
          selectedActionEvent: null,
          multiSelectAction: false,
          multiActionSelect: true,
          multipleActionEventConnected: false,
          multipletoBeProcessed: false,
          multipleActionConnectionId: null,
          selectActionEntity: false,
          entityActionName: null,
          userActionEntityList: null,
          userActionSpreadsheetsList: null,
          multiActionInput: "",
          buttonType: true,
        };
        this.actionSteps.push(newActionStep);
        this.$notify({
          title: "Success",
          message: "Action step added successfully",
          type: "success",
        });
      } else {
        this.$notify({
          title: "Warning",
          message: "Connection was not established in previous step",
          type: "warning",
        });
      }
    },
    async selectActionApp(item, app) {
      this.actionSelectApp = app;
      this.loading = true;
      item.actionAppLogo = app.logo;
      item.appInfo = app;
      item.multiActionSelect = false;
      item.multiSelectAction = true;
      if (app && app.action_events) {
        item.multipleActionsEvents = app.action_events;
      }
      this.loading = false;
      this.hardCheck = false;
    },
    async updateActionAppInfo(actionInfo) {
      let isEventFound = actionInfo.multipleActionsEvents.find((event) => {
        if (
          event &&
          event._id &&
          actionInfo.selectedActionEvent &&
          event._id === actionInfo.selectedActionEvent
        ) {
          return true;
        } else {
          return false;
        }
      });
      if (isEventFound) {
        let params = {
          record_id: actionInfo.record_id,
          appId: actionInfo.appInfo._id,
          eventName: isEventFound.eventName,
          eventDescription: isEventFound.eventDescription,
          eventType: isEventFound.eventType,
          entityName: isEventFound.entity,
          operation: isEventFound.operation,
          flowId: this.getAutomationFlowById.data._id,
          app_name: actionInfo.appInfo.app_name,
        };

        if (params.entity_id == null) {
          delete params.entity_id;
        }
        if (params.operation == "Delete") {
          //
        }
        this.loading = true;
        await store.dispatch("updateActionAppInfoInFlow", params);
        this.loading = false;
        if (
          this.getUpdateActionAppData &&
          this.getUpdateActionAppData.success
        ) {
          actionInfo.isMapping = false;
          if (this.getUpdateActionAppData.operation == "create") {
            actionInfo.record_id = this.getUpdateActionAppData.data._id;
          }
          this.actionDailogExist = true;
          this.actionData = actionInfo;
        } else {
          console.log(
            "automation flow not updated with the action app information"
          );
        }
      }
    },
    async showActionSteps() {
      if (
        this.getAutomationFlowById.data &&
        this.getAutomationFlowById.data._id
      ) {
        await store.dispatch(
          "getAllActions",
          this.getAutomationFlowById.data._id
        );
        let allEvents = this.getAllActions.data;
        for (let item of allEvents) {
          let actionEvents;
          let actionModel;
          let logo;
          await this.getAllActionAppsList();
          for (let event of this.getActionApps.data) {
            for (let app of event.action_events) {
              if (
                app.eventType == item.eventType &&
                app.operation == item.operation &&
                app.eventName == item.event_name &&
                app.entity == item.entity_name &&
                event._id == item.app_id
              ) {
                actionModel = app;
                if (actionModel) {
                  actionEvents = event.action_events;
                  logo = event.logo;
                }
              }
            }
          }
          let actionAppInfo = this.getActionApps.data.find(
            (app) => app._id == item.app_id
          );
          let newActionStep = {
            id: this.actionSteps.length,
            name: "Action Step" + this.actionSteps.length,
            allApps: this.getActionApps.data,
            selectedActionEvent: actionModel._id,
            multipleActionsEvents: actionEvents,
            multiSelectAction: true,
            multiActionSelect: false,
            multipleActionEventConnected: item.app_connection_id ? true : false,
            multipletoBeProcessed: false,
            multipleActionConnectionId: item.app_connection_id
              ? item.app_connection_id
              : null,
            multiActionInput: "",
            appInfo: actionAppInfo,
            record_id: item._id,
            buttonType: false,
            actionAppLogo: logo,
            isMapping:
              item.mapping_record && item.mapping_record.length ? true : false,
            fieldsObject: item.requiredIds,
          };
          this.actionSteps.push(newActionStep);
        }
      }
    },
    async updateActionAppInfoWithConnectionId(actionInfo) {
      let params = {
        actionId: actionInfo.record_id,
        entity_name: actionInfo.selectedActionEvent.entity,
        app_connection_id: actionInfo.multipleActionConnectionId
          ? actionInfo.multipleActionConnectionId
          : null,
        requiredIds: actionInfo.fieldsObject,
      };
      if (params.entity_id == null) {
        delete params.entity_id;
      }
      this.loading = true;
      await store.dispatch("updateActionAppInfoInFlowConnection", params);
      this.loading = false;
    },
    async saveActionEvent(item) {
      this.loading = true;
      await this.updateActionAppInfoWithConnectionId(item);
      this.$router.push(
        `/connector/map-fields/${this.getAutomationFlowById.data._id}/${item.record_id}`
      );
      if (this.getUpdateActionAppDataConnection.success) {
        this.$notify.success({
          title: "Success",
          message: "connection established successfully",
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: "failed to establish connection",
        });
      }
      this.loading = false;
    },
    async saveDeleteActionEvent(item) {
      this.loading = true;
      await this.updateActionAppInfoWithConnectionId(item);
      if (this.getUpdateActionAppDataConnection.success) {
        this.$notify.success({
          title: "Success",
          message: "connection established successfully",
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: "failed to establish connection",
        });
      }
      this.loading = false;
    },
    async activateAutomationFlow() {
      let params = {
        id: this.getAutomationFlowById.data._id,
        isActivate: this.isActivate,
      };
      await store.dispatch("makeAutomationFlowActivate", params);
      if (this.getisFlowActivated.success) {
        // this.$router.push(
        //     `/connector/all-workflows`
        // );
        this.$notify({
          title: "Success",
          message: this.getisFlowActivated.message,
          type: "success",
        });
      } else {
        this.$notify({
          title: "Error",
          message: this.getisFlowActivated.message,
          type: "error",
        });
      }
    },
    async deactivateAutomationFlow() {
      let params = {
        id: this.getAutomationFlowById.data._id,
        isActivate: false,
      };
      await store.dispatch("makeAutomationFlowActivate", params);
      if (this.getisFlowActivated.success) {
        this.$router.push(`/connector/all-workflows`);
        this.$notify({
          title: "Success",
          message: "Automation Deactivated Successfully",
          type: "success",
        });
      } else {
        this.$notify({
          title: "Error",
          message: "Error in Deactivating Flow",
          type: "error",
        });
      }
    },
    async viewMappingScreen(item) {
      this.openMappingDialog = true;
      this.actionStepInfo = item;
    },
    async fetchSpreadsheets(data) {
      if (this.selectedApp.app_name == "GoogleSheets") {
        await store
          .dispatch("fetchSpreadsheets", data.apiKey)
          .catch((error) => {
            console.log(error);
          });
      }
    },
    /* ******************************************************************************* */
    checkEvents(trig) {
      trig.multipleActionsEvents.find((item) => {
        if (
          item.action_name == item.selectedActionEvent &&
          item.operation == "Delete"
        ) {
          this.toBeProcessed = false;
          return;
        } else {
          this.toBeProcessed = true;
        }
      });
    },
    async addAction() {
      await store.dispatch("fetchAllApps");
      let getApps = this.getApps.data;
      let newActionStep = {
        id: this.actionSteps.length,
        name: "Action Step",
        appButtons: getApps,
        selectedActionEvent: null,
        multiSelectAction: false,
        multiActionSelect: true,
        multipleActionEventConnected: false,
        multipletoBeProcessed: false,
        multipleActionConnectionId: null,
        selectActionEntity: false,
        entityActionName: null,
        userActionEntityList: null,
        userSpreadsheetsList: null,
        corporationName: null,
        CorporationList: null,
        selectedChat: null,
        selectActionCorporation: false,
        multiActionInput: "",
      };
      this.actionSteps.push(newActionStep);
    },
    openAllApps() {
      this.isTriggerAppSelected = false;
      // this.TriggerSelect = true;
      this.isTriggerAppConnected = false;
    },
    openAllAppAction(item) {
      item.multiActionSelect = true;
      item.multiSelectAction = false;
      item.multipleActionEventConnected = false;
    },
    gotoMapfields(item) {
      this.loading = true;
      this.saveTriggerData(), this.saveActionData(item);
      this.$router.push(
        `/connector/map-fields?id=${this.workflowId}&_id=${item._id}`
      );
      this.loading = false;
    },
    editMapping(item) {
      this.$router.push(
        `/connector/map-fields/${this.getAutomationFlowById.data._id}/${item.record_id}?isEdit=true`
      );
    },
    viewChatgptResponse() {
      this.chatgptResponse = true;
    },
    async copyTheTextInTheInputBox() {
      try {
        await navigator.clipboard.writeText(this.quickbooksUrl);
        this.isLinkCopied = true;
        this.$message.success("Link copied successfully");
      } catch (err) {
        console.log("the error is", err);
      }
    },
    getCircleNumber(index) {
      return index + 2;
    },
    scheduleAction() {
      this.scheduleActionModel = true;
    },
    deleteActionStep(item) {
      this.$confirm("Are you sure you want to delete this step?")
        .then(async () => {
          this.loading = true;
          await store.dispatch("deleteActionStep", item.record_id);
          this.loading = false;
          if (this.getDeleteActionFlow.success) {
            let matchedActionIndex = this.actionSteps.findIndex(
              (action) => action._id == this.getDeleteActionFlow.data._id
            );
            this.actionSteps.splice(matchedActionIndex, 1);
            this.$notify({
              title: "Success",
              message: this.getDeleteActionFlow.message,
              type: "success",
            });
          } else {
            this.$notify({
              title: "Error",
              message: this.getDeleteActionFlow.message,
              type: "error",
            });
          }
        })
        .catch(() => { });
    },
    async saveSchedule(item) {
      const dateObject = new Date(this.scheduleModel);
      const parsedDate = {
        date: dateObject.getDate(),
        day: dateObject.getDay(), // 0 (Sunday) through 6 (Saturday)
        month: dateObject.getMonth(), // 0 (January) through 11 (December)
        year: dateObject.getFullYear(),
        hours: dateObject.getHours(),
        minutes: dateObject.getMinutes(),
        seconds: dateObject.getSeconds(),
        timezoneOffset: dateObject.getTimezoneOffset(),
        timezone: dateObject.toString().match(/\((.*?)\)/)[1], // Extract timezone from the string
      };
      await store.dispatch("scheduleAction", { parsedDate, item });
    },
    async bindConnection(data) {
      let params = {
        connection_id: data._id,
        appName: this.selectedApp?.app_name,
        entityName: this.automationInfo?.entity_name,
        automationId: this.automationInfo._id,
      };
      try {
        this.loading = true;
        await store.dispatch("fetchAppFieldsByName", params);
        this.loading = false;
        this.trigger_app_connection_id = data._id;
        this.isTriggerAppConnected = true;
        this.isTriggerAppDrawerOpened = false;
        if (this.selectedApp.app_name == "QuickBooks") {
          this.app_recognization_key = data.tokens.company_id;
        }
        if (this.getAppFieldsByName && this.getAppFieldsByName.data) {
          this.fieldsObject = this.getAppFieldsByName.data;
        } else {
          this.fieldsObject = null;
        }
      } catch (error) {
        return error;
      }
    },
    async bindActionConnection(data) {
      let allActions = this.actionSteps;
      let matchedAction = await allActions.find(
        (item) => item.record_id == data.actionId
      );
      if (matchedAction) {
        matchedAction.multipleActionConnectionId =
          data && data.action_app_connection_id
            ? data.action_app_connection_id
            : null;
        matchedAction.multipleActionEventConnected = true;
        this.actionDailogExist = false;
        let actionEventInfo = await matchedAction.appInfo.action_events.find(
          (event) => event._id == matchedAction.selectedActionEvent
        );
        let params = {
          connection_id: data.action_app_connection_id,
          appName: matchedAction.appInfo.app_name,
          entityName: actionEventInfo.entity,
          user_id: this.getAutomationFlowById?.data?.user_id,
        };
        this.loading = true;
        await store.dispatch("fetchAppFieldsByName", params);
        this.loading = false;
        if (this.getAppFieldsByName && this.getAppFieldsByName.data) {
          matchedAction.fieldsObject = this.getAppFieldsByName.data;
        } else {
          matchedAction.fieldsObject = null;
        }
      } else {
        return;
      }
    },
    shouldShowSaveButton(item) {
      if (item.isMapping) {
        return false;
      } else {
        return true;
      }
    },
    async fetchWebhookInstructions(appName) {
      this.loading = true;
      await this.$store.dispatch("webhookInstructions", appName);
      this.loading = false;
      this.webhookData = this.getWebhookInstructions.data;
    },
    async getActionNestedFields(ParentValue, parentIndex, matchedAction) {
      let actionEventInfo = await matchedAction.appInfo.action_events.find(
        (event) => event._id == matchedAction.selectedActionEvent
      );
      let params = {
        connection_id: matchedAction.multipleActionConnectionId,
        appName: matchedAction.appInfo.app_name,
        entityName: actionEventInfo.entity,
        data: ParentValue,
        index: parentIndex,
      };
      try {
        this.loading = true;
        await store.dispatch("fetchNestedAppFieldsByName", params);
        this.loading = false;
        if (this.getNestedAppFields && this.getNestedAppFields.data) {
          matchedAction.fieldsObject.labels[parentIndex] =
            this.getNestedAppFields.data;
        }
      } catch (error) {
        return error;
      }
    },
    async getChildFields(childValue, childIndex, parentIndex) {
      console.log("child fields called", childValue, childIndex, parentIndex)
      let params = {
        connection_id: this.trigger_app_connection_id,
        appName: this.selectedApp?.app_name,
        entityName: this.automationInfo?.entity_name,
        data: childValue,
        childIndex: childIndex,
        parentIndex: parentIndex,
      };
      console.log("paramsss", params)
      try {
        this.loading = true;
        await store.dispatch("fetchChildFieldsByName", params);
        this.loading = false;
        if (this.getChildAppFields && this.getChildAppFields.data) {
          this.fieldsObject.labels[parentIndex].children[childIndex + 1].value =
            this.getChildAppFields.data;
        }
      } catch (error) {
        return error;
      }
    },
    async getActionChildFields(
      parentField,
      childIndex,
      parentIndex,
      matchedAction
    ) {
      let actionEventInfo = await matchedAction.appInfo.action_events.find(
        (event) => event._id == matchedAction.selectedActionEvent
      );
      let params = {
        connection_id: matchedAction.multipleActionConnectionId,
        appName: matchedAction.appInfo.app_name,
        entityName: actionEventInfo.entity,
        data: parentField,
        childIndex: childIndex,
        parentIndex: parentIndex,
      };
      try {
        this.loading = true;
        await store.dispatch("fetchChildFieldsByName", params);
        this.loading = false;
        if (this.getChildAppFields && this.getChildAppFields.data) {
          matchedAction.fieldsObject.labels[parentIndex].children[
            childIndex + 1
          ].value = this.getChildAppFields.data;
        }
      } catch (error) {
        return error;
      }
    },
    async getNestedFields(ParentValue, parentIndex) {
      let params = {
        connection_id: this.trigger_app_connection_id,
        appName: this.selectedApp?.app_name,
        entityName: this.automationInfo?.entity_name,
        data: ParentValue,
        index: parentIndex,
      };
      try {
        this.loading = true;
        await store.dispatch("fetchNestedAppFieldsByName", params);
        this.loading = false;
        if (this.getNestedAppFields && this.getNestedAppFields.data) {
          this.fieldsObject.labels[parentIndex] = this.getNestedAppFields.data;
        }
      } catch (error) {
        return error;
      }
    },
    async refreshAction(ParentValue, parentIndex, matchedAction) {
      let actionEventInfo = await matchedAction.appInfo.action_events.find(
        (event) => event._id == matchedAction.selectedActionEvent
      );
      let params = {
        connection_id: matchedAction.multipleActionConnectionId,
        appName: matchedAction.appInfo.app_name,
        entityName: actionEventInfo.entity,
        data: ParentValue,
        index: parentIndex,
      };
      this.loading = true;
      await store.dispatch("fetchRefreshingFields", params);
      this.loading = false;
      if (this.getRefreshedAppFields.data) {
        matchedAction.fieldsObject.labels[parentIndex] =
          this.getRefreshedAppFields.data;
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getRefreshedAppFields.message,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.all-apps {
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1em;
}

.action-apps {
  max-height: 200px;
  display: flex;
  flex-wrap: nowrap;
}

.connectButton-action {
  width: 100%;
  font-size: 17px;
  height: 48px;
  border: none;
  background-color: #f754a2;
  margin-bottom: 10px;
  margin-top: 10px;
}

.list-item-webhooks {
  text-align: left;
  color: #606266;
  flex-direction: column;
}

.header-configure-workflow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #6b52ae;
  color: #1b4554;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding-top: 5px;
}

.configure-header {
  padding-top: 2%;
  margin-bottom: 5%;
}

.button-back {
  height: 40px;
  font-weight: 500;

  float: left;
}

.row-2 {
  justify-content: center;
}

.card {
  border: none;
  transition: max-height 0.3s ease;
  background-color: #ffffff;
  padding-bottom: 3%;
}

.circle {
  width: 24px;
  height: 24px;
  border-radius: 100px;
  border: 1px solid;
  border-color: #409eff;
  position: relative;
  margin: 0 auto 0 auto;
  align-items: center;
}

.vertical-line-configure {
  width: 1px;
  height: 350px;
  margin: 0 auto 0 auto;
  border-left: 1px dashed #d9d9d9;
}

.el-switch__label.is-active {
  color: white;
}

.circle-text {
  color: #303133;
  font-size: 14px;
}

.circle-flow {
  align-items: flex-start;
}

.card-logo-holder {
  height: 30px;
  width: 30px;
}

.card-header {
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.card-logo-button {
  border-radius: 2px;
  border: none;
  display: flex;
  align-items: center;
  margin-left: 1.3rem;
}

.more-icon-configure {
  transform: rotate(90deg);
  color: #909399;
}

.header-text {
  color: #303133;
  font-size: 19px;
}

.header-p {
  color: #909399;
  font-size: 12px;
  padding-top: 4%;
}

.button-search {
  width: 100%;
  font-size: 14px;
  color: #bdbdbd;
  border-radius: 2px;
  border: 1px solid rgb(208, 215, 222);
}

.trigger-block {
  margin: 15px;
}

.selected-flow-text {
  display: flex;
  align-items: flex-start;
  float: left;
  padding: 1%;
  font-size: 22px;
}

.app-cards {
  border: 1px solid #409eff;
  width: 100%;
  height: 40px;
  border-radius: 2px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.app-cards:hover {
  border: 1px solid #409eff;
}

.selected-app {
  width: 100%;
  border-radius: 2px;
  font-size: 16px;
  margin-bottom: 1em;
}

.select-event {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 60px;
  border-radius: 2px;
  font-size: 12px;
  color: #c2c6cd;
}

.select-event .label {
  font-size: 14px;
  color: #303133;
}

.select-dropdown {
  border-radius: 4px;
  font-size: 12px;
  border: #c2c6cd;
}

.select-dropdown:focus {
  border-color: #409eff;
}

.app-image {
  float: left;
  border: none;
}

.image-circle-text {
  color: #303133;
  font-size: 13px;
  float: right;
}

.Event {
  font-size: 17px;
  color: #303133;
}

.change-button {
  border-radius: 2px;
  border: 1px solid #409eff;
  color: #409eff;
  display: flex;
  align-items: flex-end;
  float: right;
}

.expanded-collapse {
  height: 300px;
}

.webhookUrl-Holder {
  height: 32px;
  width: 90%;
  background-color: #e4e7ed;
}

@media screen and (min-width: 600px) {
  .webhookUrl-Holder {
    width: 84%;
  }
}

.copyLink-button {
  border-radius: 2px;
}

@media screen and (min-width: 600px) {
  .copyLink-button {
    width: 16%;
  }
}

@media (min-width: 1000px) {
  .label-col {
    margin-right: 40px;
  }
}

@media (min-width: 1200px) {
  .label-col {
    margin-right: 40px;
  }
}

@media (min-width: 1600px) {
  .label-col {
    margin-right: 20px;
  }
}

.list-items {
  color: #606266;
  font-size: 14px;
  font-family: "Arimo";
}

.capture-webhook-button {
  border: 1px solid #409eff;
  height: 32px;
  width: 194px;
  color: #409eff;
  border-radius: 0px;
}

.vertical-line {
  height: 22px;
  display: inline-block;
  border-left: 0.5px solid gray;
  border-right: 0.5px solid gray;
}

.arrow-down {
  display: inline-block;
}

.scroll-container {
  overflow-x: auto;
  max-height: 300px;
}

.signin-to-trigger-app {
  width: 100%;
  color: #ffffff;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.vertical-line-for-number-circle {
  position: absolute;
  height: 45%;
  width: 1px;
  left: 21%;
  border-left: 1px dashed #d9d9d9;
  transform: translateX(-50%);
}

.wrapper.collapsed .vertical-line-for-number-circle {
  height: 100%;
}

.for-border {
  border: 1px solid #409eff;
  border-radius: 6px;
}

.notify-text {
  color: #606266;
  font-size: 14px;
  margin-left: -7rem;
}

.divider {
  display: flex;
  flex-direction: column;
  border-width: 2px;
}

.list-item-webhooks {
  text-align: left;
  color: #606266;
}

.custom-option.is-disabled {
  color: rgb(186, 184, 184);
  cursor: not-allowed;
}

.container {
  display: flex;
  align-items: center;
  /* Center items vertically */
}

.el-button-refresh {
  height: 41px !important;
}

.table_button {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  height: 25px !important;
}

.button-back {
  color: #333;
}

.selected-flow-text h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.switch-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logo img {
  width: 70px;
  padding-left: 4px;
  float: left;
  // background-color: #524c4c;
}

.logo-container {
  position: relative;
}

.logo {
  transition: transform 0.3s ease;
  padding: 10px;
}
</style>