import {createApp} from "vue";
import "element-plus/dist/index.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import router from "./router/index";
import App from "./App.vue";
import store from './store/store';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import './assets/styles/app.scss';
import 'bootstrap/dist/css/bootstrap.css';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'
import mitt from 'mitt';
import VueTimezoneSelect from 'vue-timezone-select';
const emitter = mitt();
const app = createApp(App)
app.config.globalProperties.emitter = emitter;
app.use('store', store);
app.use(router)
app.use(store)
app.use(ElementPlus);
app.use(bootstrap)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.component('vue-timezone-select', VueTimezoneSelect);
app.mount("#app");