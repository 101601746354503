<template>
<div v-loading="loading">
    <el-row>
      <el-col :lg="12" :xs="24" :sm="24" :md="12">
        <BannerComponent></BannerComponent>
      </el-col>
      <!-- <el-col :lg="3" :sm="3" :md="3"></el-col> -->
      <el-col :lg="11" :xs="24" :sm="21" :md="11">
        <div class="total-column-2">
          <div class="signup">
            <div
              class="heading"
              style="color: gray; margin-top: 20px; margin-left: 10px"
            >
              <h3 style="font-size: small; font-weight: 100; margin-top: 3px">
                Already have an account?
              </h3>
            </div>
            <div
              style="margin-top: 20px; margin-left: 10px; color: deepskyblue"
            >
              <router-link
                to="/"
                style="
                  text-decoration: none;
                  cursor: pointer;
                  font-size: small;
                  color: rgb(25, 137, 250);"
                >Log In</router-link
              >
              <!-- <router-view/> -->
            </div>
          </div>
          <div style="margin-bottom: 50px;">
          <div class="create-heading">
            <div style="margin-left: -10px;">
              <h2 style="font-weight: 600; margin-bottom: 8px">Forgot Password</h2>
            </div>
          </div>
          <div class="create-heading" style="line-height: 30px; margin-left: -10px;">
            <h4 style="color: darkgray; font-weight: 100">
            Don't worry if you have forgotten your password. Simply
            provide the email address you used to sign up, and we will
            send you a link to reset it.
            </h4>
          </div>
          <div class="form">
            <el-form
              label-width="150px"
              label-position="left"
              class="demo-dynamic"
              :model="ruleForm" :rules="rules" ref="ruleForm" 
            >
              <div
                class="emial-label"
                style="text-align: left; "
              >
                <el-form-item class="form-item" prop="email" label="Email" style="margin-bottom: 40px;" >
                  <el-input
                    style="width: 380px; height: 60px; font-size: 13px; "
                    placeholder="Enter your Email"
                    :loading="submitLoading"
                    v-model="ruleForm.email"
                  ></el-input>
                </el-form-item>
              </div>
            
              <div class="buttons">
                <div class="create-button">
                  <el-button
                    type="primary"
                    class="button"
                    style="width: 380px; height: 50px; margin-right: 185px"
                    @click="submitForm('ruleForm')"
                    >Send Password Reset Email</el-button
                  >
                  <div style="text-align: left;">
                  <p class="mt-3 mt-lg-4" style="color: gray; font-size: small; font-weight: 100;">
                  Don't have an eSigns account ?
                  <a href="/signUp" class="text-primary" style="color: #1989FA">Sign Up</a>
                </p>
                </div>
                </div>
                <br />
              </div>
            </el-form>
          </div>
        </div>
    </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import store from '../store/store';
// import { computed } from 'vue';
import BannerComponent from '../components/BannerComponent.vue'
export default {
    data() {
    return {
      userDetails: {
        email: null,
        password: null
      },
      loading:false
      }
  },
    components: {
      BannerComponent
    },
    methods: {
        submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            setTimeout(() => {
            alert("Password reset email sent successfully.");
            this.$router.push('/forgotPasswordVerification');
          }, 2000);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
}
}

</script>

<style lang="scss">

</style>