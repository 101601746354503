<template>
  <div class="main-container-workflows">
    <div :class="{ 'side-nav-workflows': true, 'expanded': !isCollapse }">
      <el-menu :default-active="$route.path" class="el-menu-vertical-demo" @select="handleMenuSelect">
        <el-tooltip effect="dark" content="Dashboard" placement="right">
        <el-menu-item index="/connector">
          <img
            src="../assets/DashBoardIcons/Dashboard.png"
            alt=""
            class="sidebarlogo mr-1"
            style="margin-bottom: -5px; height: 22px; margin-right:20px; width: 22px;"
          />
          
            <li @click="opencardView" :class="{ active: $route.path === '/connector' }">Dashboard</li>
         
          </el-menu-item>
           </el-tooltip>
        <el-tooltip effect="dark" content="Workflows" placement="right">
        <el-menu-item index="/connector/all-workflows" class="inwork">
          <img
            src="../assets/DashBoardIcons/Workflows.png"
            alt=""
            class="sidebarlogo mt-1"
            style="margin-bottom: -5px; margin-top: 1px; height: 22px; margin-right:20px; width: 22px;"
          />
      
            <li @click="openWorkFlows" >Workflows</li>
        
        </el-menu-item>
      </el-tooltip>


        <el-tooltip effect="dark" content="Logs" placement="right">
        <el-menu-item index="/connector/flowLogs">
         
          <img
            src="../assets/DashBoardIcons/TasksAllotted.png"
            alt=""
            class="sidebarlogo mt-1"
            style="margin-bottom: -5px; margin-top: 1px; margin-right:20px; height: 22px; width: 22px;"
          />
        
            <li @click="openflowLogs" :class="{ active: $route.path === '/connector/flowLogs' }">Logs</li>
       
        </el-menu-item>
      </el-tooltip>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
  },
  data() {
    return {
      isCollapse: true,
    };
  },
  methods: {
    openSettings() {
      this.$router.push('/SettingsPage');
    },
    handleMenuSelect(index) {
      this.$router.push(index);
    },
    opencardView() {
      this.$router.push('/connector');
    },
    async openWorkFlows() {
      this.$router.push(this.$route.query.redirect || "/connector/all-workflows");
    },
    async openflowLogs() {
      this.$router.push(this.$route.query.redirect || "/connector/flowLogs");
    },
  },
};
</script>

<style scoped>
.main-container-workflows {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 110vh;
  overflow: hidden;
  
}

.side-nav-workflows {
  background-color: #343746 ;
  width: 58px; /* Initial width when collapsed */
  overflow-y: auto;
  list-style: none;
  cursor: pointer;
  transition: width 0.3s ease; /* Add transition for smooth animation */
}

.side-nav-workflows.expanded {
  width: 170px; /* Width when expanded */
}

.side-nav-workflows ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.side-nav-workflows li {
  list-style: none;
  display: flex;
  align-items: center;
  background-color: #343746 ;
  color: #ffffff;
}

.side-nav-workflows li:hover {
  list-style: none;
  background-color: #ffffff;
  color: #343746;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow effect */
  transform: scale(1.1); /* Zoom effect - adjust value as needed */
  transition: transform 0.3s ease; /* Smooth transition for the zoom effect */
}

.el-menu-item {
  text-decoration: none;
  color: #606266;
  font-size: 16px;
}

.inwork {
  background-color: #ffffff !important;
  color:#343746 !important;
}


</style>
