<template>
    <div class="content-section">
        <el-row>
            <el-col :span="24">
                <div>
                    <h3 style="float: left; margin: 1px;">Account Management</h3><br />
                    <p style="float: left; font-size: 13px; color: #767676; margin-left: -15em;">View and manage all your
                        account associated information.</p>
                </div>
            </el-col>
        </el-row>

        <el-row style="margin-top: 2%;" :span="24">
            <el-tabs v-model="activeName" @tab-click="handleClick" :span="24" >
                <!-- profile -->
                <el-tab-pane label="Profile" name="first" style="margin-top: 2%;">
                    <el-row class="wrapper-profile">
                        <el-card shadow="always"
                            style="margin-bottom: 18px; margin-top: 18px; width: 1200px; height: 80vh;">
                            <template #header>
                                <div style="padding: 10px;" >
                                    <span class="clearfix" style="float: left; font-weight: 600; color: #767676; padding:">Profile Settings</span>
                                </div>
                            </template>
                            <el-row :gutter="10" :span="24">
                                <el-col :span="24" :sm="12" style="margin-bottom: 20px; height: 40px;">
                                    <el-form @submit="saveForm" >
                                        <div class="list-group">
                                            <div class="form-item">
                                                <label for="first-name" class="label-left">First Name</label>
                                                <el-input id="first-name" v-model="formData.firstName"
                                                    style="font-size: 13px; height: 40px;"
                                                    placeholder="Enter your first name"></el-input>
                                            </div><br />
                                            <div class="form-item">
                                                <label for="Website url" class="label-left">Website URL</label>

                                                <el-input id="Website url" v-model="formData.websiteURL"
                                                    style="font-size: 13px; height: 40px;"
                                                    placeholder="Enter your Website url"></el-input>
                                            </div> <br />
                                            <div class="form-item">
                                                <label for="city" class="label-left">City</label>
                                                <el-input id="city" v-model="city" style="font-size: 13px; height: 40px;"
                                                    placeholder="Enter your City"></el-input>
                                            </div><br />
                                            <div class="form-item">
                                                <label for="Contact" class="label-left">Contact</label>

                                                <el-input id="Contact" v-model="formData.contact"
                                                    style="font-size: 13px; height: 40px;"
                                                    placeholder="Enter your Contact"></el-input>
                                            </div>
                                            <div class="form-item" style="margin-left: -9px;">
                                                <el-button style=" margin: 10px; width: 140px; margin-top: 30px;"
                                                    type="primary"  @click="successNotify">Save Changes</el-button>
                                            </div><br />
                                        </div>
                                    </el-form>
                                </el-col>
                                <el-col :span="24" :sm="12" style="margin-bottom: 20px; height: 30px;">

                                    <el-form>
                                        <div class="list-group">
                                            <div class="form-item">
                                                <label for="last-name" class="label-left">Last Name</label>

                                                <el-input id="last-name" v-model="formData.lastName"
                                                    style="font-size: 13px; height: 40px;"
                                                    placeholder="Enter your last name"></el-input>
                                            </div><br />
                                            <div class="form-item">
                                                <label for="address" class="label-left">Address</label>

                                                <el-input id="address" v-model="formData.address"
                                                    style="font-size: 13px; height: 40px;"
                                                    placeholder="Address"></el-input>
                                            </div><br />
                                            <div class="form-item">
                                                <label for="Country" class="label-left">Country</label>

                                                <el-input id="Country" v-model="country" style="font-size: 13px; height: 40px;"
                                                    placeholder=""></el-input>
                                            </div><br />
                                            <div class="form-item">
                                                <label for="Contact" class="label-left">State</label>

                                                <el-input id="Contact" v-model="formData.state"
                                                    style="font-size: 13px; height: 40px;" placeholder=""></el-input>
                                            </div><br />
                                        </div>
                                    </el-form>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-row>
                </el-tab-pane>
                <!-- Password and security -->

                <el-tab-pane label="Password and Security" name="second">
                    <div class="tab-content">
                        <el-row class="wrappe-profiler">
                            <el-card class="box-card" shadow="always"
                                style="margin-bottom: 18px; margin-top: 18px; width: 1200px; height: 60vh;">
                                <template #header>
                                    <div style=" padding: 10px;">
                                        <span
                                            style="float: left; font-weight:600; color: #767676; text-align-last: initial;">Password
                                            and Security</span>
                                        <el-button style="float: right; padding: 3px " type="text">Enable</el-button>
                                    </div>
                                </template>
                                <template>
                                    <div style=" padding: 10px;">
                                        <span style="float: left; font-weight:600; color: #767676;">Password and
                                            Security</span>
                                        <el-button style="float: right; padding: 3px " type="text">Enable</el-button>
                                    </div>
                                </template>
                                <el-row :gutter="10" :span="24">
                                    <el-col :span="12" style="margin-bottom: 20px; height: 30px;">
                                        <el-form>
                                            <div class="list-group">
                                                <div class="form-item">
                                                    <label for="first-name" class="label-left">Current Password</label>
                                                    <el-input id="first-name" v-model="currentPassword"
                                                        style="font-size: 13px; height: 40px;"
                                                        placeholder="Enter your Current Password"></el-input>
                                                </div><br />
                                                <div class="form-item">
                                                    <label for="Website url" class="label-left">New Password</label>

                                                    <el-input id="Website url" v-model="newPassword"
                                                        style="font-size: 13px; height: 40px;"
                                                        placeholder="Enter your New Password"></el-input>
                                                </div> <br />
                                                <div class="form-item" style="margin-left: -9px;">
                                                    <el-button style="text-align: left; margin: 10px; width: 140px;"
                                                        type="primary" @click="saveNotify">Save Password</el-button>
                                                </div><br />
                                            </div>
                                        </el-form>
                                    </el-col>
                                    <el-col :span="12" style="margin-top: 99px; height: 30px;">
                                        <el-form>
                                            <div class="list-group">
                                                <div class="form-item">
                                                    <label for="confirm-new-password" class="label-left">Confirm New
                                                        Password</label>
                                                    <el-input id="confirm new password" v-model="confirmPassword"
                                                        placeholder="Confirm New Password"
                                                        style="font-size: 12px; height: 40px;"></el-input>
                                                </div><br />
                                            </div>
                                        </el-form>
                                    </el-col>
                                </el-row>
                            </el-card>
                        </el-row>
                    </div>
                </el-tab-pane>

                <!-- Delete Account -->
                <el-tab-pane label="Delete Account" name="third" style="color: #767676;">
                    <div class="tab-content">
                        <el-row class="wrapper-profile">
                            <el-card class="box-card" shadow="always"
                                style="margin-bottom: 18px; margin-top: 18px; width: 1200px; height: 60vh;">
                                <template #header>
                                    <div style=" padding: 10px;">
                                        <span style="float: left; font-weight:600; color: #767676;">Delete Account</span>
                                    </div>
                                </template>
                                <el-row :gutter="10" :span="24">
                                    <el-card>
                                        <div
                                            style="padding: 10px; background-color: #fff3cd; color:#856404; border-color: #ffeeba; border: solid: -1px; font-size: 13px;">
                                            <ul style="text-align: left; margin-bottom: 3px;">
                                                <li>If you delete your account, all the data associated with the account
                                                    will be deleted permanently.</li>
                                                <li>Data from all the applications i.e Pabbly Subscription Billing, Pabbly
                                                    Connect, Pabbly Email Marketing, Pabbly Form Builder, and Pabbly Email
                                                    Verification will be deleted.</li>
                                                <li>Once the data is deleted, it cannot be recovered.</li>
                                            </ul>
                                        </div>
                                    </el-card>
                                    <div style="font-size: 13px; font-weight: 550; margin: 15px; text-align: left">
                                        Enter Your Current password to verify that's you.
                                        <br />
                                        <el-input id="password" v-model="password" padding="10"
                                            style="height: 40px;"></el-input>
                                        <div
                                            style="font-size: 13px; font-weight: 500; margin: 15px; text-align: left; color: #ccc; margin-left: ;">
                                            Note - If you do not have a password, simply click on "Forgot Password" and
                                            create a new password.
                                        </div>
                                        <div class="form-item" style="margin-left: -9px;">
                                            <el-button style=" margin: 10px; width: 140px;" type="danger" @click="deleteNotify">Delete
                                                Account</el-button>
                                        </div><br />
                                    </div>
                                </el-row>
                            </el-card>
                        </el-row>
                    </div>
                </el-tab-pane>


            </el-tabs>
        </el-row>
    </div>
</template>

<script>
import { ElRow, ElCol, ElTabs, ElTabPane, ElCard, ElInput } from 'element-plus';

export default {
    components: {
    ElRow,
    ElCol,
    ElTabs,
    ElTabPane,
    ElCard,
    ElInput
  },
    data() {
        return {
            formData: {
            firstName: '',
            websiteURL: '',
            city: '',
            contact: '',
            lastName: '',
            country: '',
            state: '',
            address: '',
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            },
            activeName: 'first',
        };
    },
    methods: {
        saveForm() {
      // Call the saveFormData action with the form data
      this.$store.dispatch('saveFormData', this.formData)
        .then(() => {
          console.log('Form data saved successfully');
        })
        .catch(error => {
          console.error('Failed to save form data', error);
        });
    },
    postData(e)
    {
        console.warn(this.posts)
        e.preventDefault();
    }, 
    successNotify() {
        this.$notify({
          title: 'Success',
          message: 'Changes saved successfully',
          type: 'success'
        });
      },
      saveNotify() {
        this.$notify({
          title: 'Success',
          message: 'Password saved successfully',
          type: 'success'
        });
      },
      deleteNotify() {
        this.$notify({
          title: 'Success',
          message: 'Account deleted successfully',
          type: 'success'
        });
      },
    }
};
</script>
<style>
.content-section {
    padding: 10px;
    padding-left: 10px;
    top: 0;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.label-left {
    float: left;
    width: 150px;
    align-items: flex-start;
    margin-right: auto;
    padding: 10px;
    display: flex;
    font-size: 14px;
}
.wrapper {
  display: flex;
  justify-content: center;
}

.form-item {
    height: 80px;
    color: #677788;

}

.list-group {
    border-radius: 0;
    margin-bottom: 10px;
    padding: 1em;
    border: none;
    /* color: #9299b8; */
    align-content: flex-start;

    &.active {
        color: #f754a2;
        border-left: 2px solid #f754a2;
        font-size: 15px;
    }
}

.clearfix:after {
    clear: both
}</style>