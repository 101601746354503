<template>
    <div v-loading="loading" element-loading-spinner="el-loading-spinner" element-loading-background="rgba(0, 0, 0, 0.1)">
        <el-container class="connection-container">
            <el-header class="drawer">
                <el-col :span="4">
                    <el-button class="drawer-logo">
                        <img :src="propsObject.actionAppLogo" width="39" height="39" />
                    </el-button>
                </el-col>
                <br />
                <el-col :span="20">
                    <div class="drawer-title">
                        Connect to {{ propsObject.appInfo.app_name }}
                    </div>
                    <div class="drawer-title-p">
                        You can manage all of your connections.Every connection is secure.
                    </div>
                </el-col>
            </el-header>

            <el-main style="flex-grow: 1;">
                <el-card shadow="never">
                    <template #header>
                        <div class="drawer-card-header">
                            <span>{{ propsObject.appInfo.app_name }}</span>
                        </div>
                    </template>
                    <div>
                        <el-radio-group v-model="isExisted" direction="horizontal">
                            <el-radio label="Select Existing Connection" size="large">
                                <span class="drawer-card-header">
                                    Choose Existing Connection
                                </span>
                            </el-radio>
                            <el-radio label="Add New Connection" size="large">
                                <span class="drawer-card-header">
                                    Create New Connection
                                </span>
                            </el-radio>
                        </el-radio-group>
                    </div><br />
                    <el-col v-if="isExisted === 'Select Existing Connection'" class="select-connections">
                        Select Connections
                        <el-select v-model="connectionModel" placeholder="Select any connection" size="large"
                            v-if="isExisted === 'Select Existing Connection'" style="width: 90%;">
                            <el-option v-for="item in connections" :key="item._id" :label="item.name"
                                :value="item"></el-option>
                        </el-select>
                        <el-dropdown v-if="isExisted === 'Select Existing Connection'" trigger="click" style="float:right;">
                            <el-icon class="more-icon">
                                <MoreFilled />
                            </el-icon>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item @click="updateConnectionDialog = true" :disabled="!connectionModel">
                                        <el-icon>
                                            <Edit />
                                        </el-icon>
                                        Update Connection</el-dropdown-item>
                                    <el-dropdown-item @click="deleteConnectionDialog = true" :disabled="!connectionModel">
                                        <el-icon>
                                            <View />
                                        </el-icon>
                                        Delete Connection</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </el-col>
                    <el-col v-else class="new-name">
                        <span style="color: red"> * </span>
                        New Connection Name
                        <el-input size="large" type="textarea" v-model="textarea" style="margin-bottom: 15px;">
                        </el-input>
                        <el-row v-if="allLabels && isExisted != 'Select Existing Connection'" class="flex-row">
                            <el-form label-position="top" :rules="allLabels.formRules" :model="allLabels.formModel">
                                <el-form-item v-for="(label, index) in allLabels.labels" :key="index" :label="label.label"
                                    :prop="label.key">
                                    <el-input size="large" :placeholder="label.placeHolder"
                                        v-model="allLabels.formModel[label.key]"></el-input>
                                    <template v-if="label.textBefore">
                                        {{ label.textBefore }}&nbsp;
                                    </template>

                                    <el-link type="primary" v-if="label.link" :href="label.href">{{ label.link }}</el-link>

                                    <template v-if="label.textAfter">
                                        &nbsp;{{ label.textAfter }}
                                    </template>
                                </el-form-item>
                            </el-form>
                        </el-row>
                    </el-col>
                    <el-dialog v-model="updateConnectionDialog" title="Update Connections" width="30%" draggable>
                        <span class="textStyle"> Connection Name </span><br /><br />
                        <el-input size="large" type="textarea" v-model="textareaOfUpdateDialog"></el-input>
                        <el-checkbox v-model="actionUpdateConnectionsData"> Update Connections
                            Data</el-checkbox><br /><br />
                        <div v-if="needConnectButton != true">
                            <el-button type="primary" size="large" class="button-connect-drawer"
                                :disabled="!actionUpdateConnectionsData">Connect with {{
                                    propsObject.appInfo.app_name }}</el-button>
                        </div>
                        <template #footer>
                            <span class="dialog-footer">
                                <el-button @click="updateConnectionDialog = false">Cancel</el-button>
                                <el-button type="primary" @click="updateConnectionNameEvent">
                                    Confirm
                                </el-button>
                            </span>
                        </template>
                    </el-dialog>
                    <el-dialog v-model="deleteConnectionDialog" title="Do you really want to delete this connection?"
                        width="30%" draggable>
                        This action cannot be undone
                        <template #footer>
                            <span class="dialog-footer">
                                <el-button @click="deleteConnectionDialog = false">Cancel</el-button>
                                <el-button type="primary" @click="deleteConnectionEvent">
                                    Confirm
                                </el-button>
                            </span>
                        </template>
                    </el-dialog>
                    <div v-if="showConnectButton && isExisted !== 'Select Existing Connection'">
                        <el-button type="primary" @click="connectToOuath" size="large" class="oauth-connect-button">Connect
                            with {{
                                propsObject.appInfo.app_name }}</el-button><br />
                        <span class="drawer-title-p">Please authenticate your {{ actionLogo.app_name }} app to
                            allow access to Connector.</span>
                    </div>
                </el-card>
            </el-main>
        </el-container>
        <el-footer class="drawer-footer">
            <el-button @click="cancelClick">Cancel</el-button>
            <el-button v-if="isExisted !== 'Select Existing Connection'" type="primary"
                @click="verifyAndSaveCredentials">Save</el-button>
            <el-button v-if="isExisted === 'Select Existing Connection'" type="primary"
                @click="SaveExisistingConnection">Save</el-button>
        </el-footer>

    </div>
</template>
<script>
import store from '../../store/store';
import { mapGetters } from 'vuex';
import connectionsHelper from '@/mixins/connectionsHelper';
export default {
    props: {
        propsObject: {
            type: Object,
            required: true
        },
        triggerAppInfo: {
            type: Object
        },
        actionDailogExist: {
            type: Boolean,
            required: true
        },
        actionLogo: {
            type: Object,
            required: true
        },
        actionEventConnected: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            isExisted: 'Select Existing Connection',
            actionUpdateConnectionsData: false,
            textareaOfUpdateDialog: '',
            needConnectButton: false,
            TokenInputAction: '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',
            connectionModel: null,
            connections: null,
            updateConnectionDialog: false,
            deleteConnectionDialog: false,
            loading: false,
            textarea: null,
            twilioConnection: null,
            twilioForm: {
                accountId: '',
                secretToken: '',
                twilioNumber: null
            },
            allLabels: null,
            appName: null,
            dontNeedConnectButtonApps:
                [
                    'ChatGpt',
                    'Twilio',
                    'NimbleStaffing'
                ],
            queryData: null,
            textareaOfDialog: null
        }
    },
    computed: {
        ...mapGetters([
            "getTwilioConnection",
            "getAllConnections",
            "getOauthServerUrl",
            'getTokensWithConnection',
            "getLabels",
            "getValidateAndSaveCredentials",
            "getOauthTokens",
            "getUpdateActionAppDataExistingConnection"
        ]),
        showConnectButton() {
            const { isExisted, appInfo } = this.propsObject;
            const appName = appInfo.app_name;
            return isExisted !== 'Select Existing Connection' && !this.dontNeedConnectButtonApps.includes(appName);
        },
    },
    created() {
        this.interval = setInterval(this.checkLocalStorage, 2000);
    },
    watch: {
        async queryData(newValue) {
            console.log("action watcher calling", newValue)
            if (newValue) {
                await this.getTokensUsingAuthorizationCode(newValue)
            }
        },
    },
    async mounted() {
        this.removeConnectButton()
        this.fetchExistingConnections()
        this.getAndUpdateLabels();
    },
    unmounted() {
        clearInterval(this.interval);
    },
    mixins: [connectionsHelper],
    methods: {
        async checkLocalStorage() {
            console.log("local storage calling")
            let storageInfo = await localStorage.getItem('extractCode')
            this.queryData = await JSON.parse(storageInfo)
            console.log("this.queryData action ", this.queryData)
            await localStorage.removeItem('extractCode');
        },
        removeConnectButton() {
            this.appName = this.propsObject.appInfo.app_name
            if (this.appName == 'ChatGpt') {
                this.needConnectButton = true;
            }
        },
        async fetchExistingConnections() {
            this.loading = true
            const userData = localStorage.getItem('user-info')
            let userInfo = JSON.parse(userData)
            let params = {
                user_id: userInfo.user_id._id,
                appName: this.appName,
            }
            await store.dispatch("fetchExisistingConnections", params)
            this.loading = false
            if (this.getAllConnections.status) {
                if (this.getAllConnections.data && this.getAllConnections.data.length) {
                    this.connections = this.getAllConnections.data
                    this.connections = this.getAllConnections.data
                    console.log("this.connections", this.connections)
                    let newLength = this.connections.length + 1
                    this.textarea = this.appName + ' ' + '#' + newLength
                    this.$notify.success({
                        title: "Success",
                        message: this.getAllConnections.message,
                    });
                }
                else {
                    this.$notify.warning({
                        title: "Warining",
                        message: this.getAllConnections.message,
                    });
                }
            }
            else {
                this.$notify.error({
                    title: "Error",
                    message: this.getAllConnections.message,
                });
            }
        },
        async getAndUpdateLabels() {
            this.loading = true
            await store.dispatch('fetchLabels', this.propsObject.appInfo.app_name)
            this.loading = false
            if (this.getLabels.status) {
                this.allLabels = this.getLabels.data
            }
            else {
                return
            }
        },
        async connectToOuath() {
            if (!this.textarea) {
    this.$message({
      showClose: true,
      message: 'New Connection Name is required.',
      type: 'error'
    });
    return;
  }
            await store.dispatch('getOauthAppServerUrl', this.propsObject.appInfo.app_name)
            if (this.getOauthServerUrl && this.getOauthServerUrl.data && this.getOauthServerUrl.data.serviceProviderUrl) {
                let url = `${this.getOauthServerUrl.data.serviceProviderUrl}&state=${this.propsObject.record_id}`
                window.open(url, '_blank', 'width=800,height=600');
            }
        },
        async deleteConnectionEvent() {
            let connectionId = this.connectionModel._id
            let response = await this.deleteConnection(connectionId)
            if (response.status) {
                let indexValue = await this.connections.findIndex((obj) => obj._id == connectionId)
                this.connections.splice(indexValue, 1);
                this.connectionModel = null
                this.deleteConnectionDialog = false
                this.$notify.success({
                    title: "Success",
                    message: response.message,
                });
            } else {
                this.$notify.error({
                    title: "Error",
                    message: response.message,
                });
            }
        },
        async updateConnectionNameEvent() {
            let name = this.textareaOfUpdateDialog
            let connectionId = this.connectionModel._id
            let response = await this.updateConnectionName(name, connectionId)
            if (response.status) {
                let matchedId = await this.connections.find((obj) => obj._id == connectionId)
                console.log("matchedId", matchedId)
                matchedId.name = name
                this.updateConnectionDialog = false
                this.$notify.success({
                    title: "Success",
                    message: response.message,
                });
            } else {
                this.$notify.error({
                    title: "Error",
                    message: response.message,
                });
            }
        },
        async verifyAndSaveCredentials() {
            const userData = localStorage.getItem('user-info')
            let userInfo = JSON.parse(userData);
            const appName = this.appName;
            const userId = userInfo.user_id._id;
            let params = {
                labels: this.allLabels,
                eventType: "Action",
                actionId: this.propsObject.record_id,
                connectionName : this.textarea
            }
            this.loading = true;
            await this.$store.dispatch('validateAndSaveCredentials', { appName, userId, params });
            this.loading = false;
            if (this.getValidateAndSaveCredentials.data && this.getValidateAndSaveCredentials.data._id) {
                let action_app_connection_id = this.getValidateAndSaveCredentials.data._id;
                let data = {
                    action_app_connection_id: action_app_connection_id,
                    actionId: this.propsObject.record_id
                }
                this.emitter.emit('setActionConnection', data)
                this.$notify.success({
                    title: "Success",
                    message: "connection established successfully",
                });
            } else {
                this.$notify.error({
                    title: "Error",
                    message: "Error in establishing connection",
                });
            }
        },
        async updateActionAppInfoWithConnectionId(connection_id) {
            let params = {
                actionId: this.propsObject.record_id,
                app_connection_id: connection_id,
            };
            this.loading = true;
            await store.dispatch("updateActionAppInfoInFlowConnection", params);
            this.loading = false;
        },
        async getTokensUsingAuthorizationCode(value) {
            let params = {
                queryParams: value,
                user_id: this.triggerAppInfo.data.user_id,
                appName: this.propsObject.appInfo.app_name,
                connectionName: this.textarea

            }
            this.loading = true
            await store.dispatch('getOauthTokens', params)
            this.loading = false
            if (this.getOauthTokens.data && this.getOauthTokens.data._id) {
                let action_app_connection_id = this.getOauthTokens.data._id;
                let data = {
                    action_app_connection_id: action_app_connection_id,
                    actionId: value.state
                }
                this.emitter.emit('setActionConnection', data)
                this.$notify.success({
                    title: "Success",
                    message: "connection established successfully",
                });
            } else {
                this.$notify.error({
                    title: "Error",
                    message: "Error in establishing connection",
                });
            }
        },
        async SaveExisistingConnection() {
            let data = {
                action_app_connection_id: this.connectionModel._id,
                actionId: this.propsObject.record_id
            }
            console.log("data",data)
            this.emitter.emit('setActionConnection', data)
            // let params = {
            //     connection_id: this.connectionModel._id,
            //     actionId: this.propsObject.record_id
            // }
            // await store.dispatch("updateActionwithExistingConnection", params)
            // let response = this.getUpdateActionAppDataExistingConnection
            // if (response && response.status) {
            //     this.$notify.success({
            //         title: "Success",
            //         message: "connection established successfully",
            //     });
            // }
            // else {
            //     this.$notify.error({
            //         title: "Error",
            //         message: "Error in establishing connection",
            //     });
            // }
        }
    }
}

</script>
<style lang="scss" scoped>
.connection-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.new-name {
    color: #606266;
    font-size: 16px;
}

.ui-card {
    margin: 20px;
}

.ui-content {
    margin: 20px;
}

.wide-input {
    width: 260px;
}

.info-section {
    color: darkgray;
    font-size: 13px;
    font-weight: 100;
}

.info-text {
    color: darkgray;
    font-size: 13px;
    font-weight: 100;
}

.info-link {
    color: cornflowerblue;
}

.drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    color: #1b4554;
    position: fixed;
    height: 90px;
    top: 0;
    left: 50;
    right: 0;
    z-index: 999;
}

.drawer {
    top: 0;
    position: fixed;
    display: flex;
    align-items: flex-start;
    background-color: #ffffff;
    padding: 1%;
    height: 80px;
    border: 1px solid #e5e5e5;
    z-index: 1;
    right: 0;
    width: 40%;
}

.drawer-title-p {
    color: #909399;
    font-size: 14px;
}

.linkText {
    color: #909399;
    font-size: 14px;
}

.drawer-title {
    color: #303133;
    font-size: 18px;
}

.drawer-logo {
    border-radius: 0px;
    border: none;
    display: flex;
    align-items: center;
}

.select-box .el-input__inner {
    width: 340px;
}

.oauth-connect-button {
    margin-top: 10px;
}

.drawer-card-header {
    color: #303133;
    font-size: 16px;
}

.drawer-footer {
    background-color: #ffffff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.button-connect-drawer {
    margin-top: 10px;
}

.textStyle {
    font-size: 18px;
}

.tokenField {
    margin-bottom: 10px;
}

.el-input--large .el-input__wrapper {
    background-color: red;
}

.select-connections {
    color: #606266;
    font-size: 16px;
    margin-bottom: 10px;
}

.flex-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>