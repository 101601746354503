<template>
  <div v-loading="loading" element-loading-spinner="el-loading-spinner" element-loading-background="rgba(0, 0, 0, 0.1)">
    <el-container style="height: 100vh; display: flex; flex-direction: column;">
      <el-header class="drawer">
        <el-col :span="4">
          <el-button circle class="drawer-logo">
            <img :src="drawerLogo" width="39" height="39" />
          </el-button>
        </el-col>
        <br />
        <el-col :span="20">
          <div class="drawer-title">
            Connect to {{ selectedApp.app_name }}
          </div>
          <div class="drawer-title-p">
            You can manage all of your connections.Every connection is secure.
          </div>
        </el-col>
      </el-header>
      <el-main style="flex-grow: 1;">
        <el-card shadow="never">
          <template #header>
            <div class="drawer-card-header">
              <span>{{ selectedApp.app_name }}</span>
            </div>
          </template>
          <div>
            <div>
              <el-radio-group v-model="isExisted" direction="horizontal" style="display: inline-block;">
                <el-radio label="Select Existing Connection" size="large">
                  <span class="drawer-card-header">
                    Choose Existing Connection
                  </span>
                </el-radio>
                <el-radio label="Add New Connection" size="large">
                  <span class="drawer-card-header">
                    Create New Connection
                  </span>
                </el-radio>
              </el-radio-group>
            </div>
            <el-col v-if="isExisted === 'Select Existing Connection'"
              style="color: #606266; font-size: 16px; margin-top: 20px;">
              Select Connections
            </el-col>
            <el-col v-else style="color: #606266; font-size: 16px; margin-bottom: 10px; margin-top: 20px;">
              <span style="color: red"> * </span>
              New Connection Name
            </el-col>
            <el-col style="margin-top:20px">
              <el-input v-if="isExisted != 'Select Existing Connection'" size="large" type="textarea" v-model="textarea">
                {{ selectedApp.app_name }}
              </el-input>
            </el-col>
            <el-row v-if="allLabels && isExisted != 'Select Existing Connection'" class="flex-row">
              <el-form label-position="top" :rules="allLabels.formRules" :model="allLabels.formModel">
                  <el-form-item v-for="(label, index) in allLabels.labels" :key="index" :label="label.label"
                      :prop="label.key">
                      <el-input size="large" :placeholder="label.placeHolder"
                          v-model="allLabels.formModel[label.key]"></el-input>
                      <template v-if="label.textBefore">
                          {{ label.textBefore }}&nbsp;
                      </template>

                      <el-link type="primary" v-if="label.link" :href="label.href">{{ label.link }}</el-link>

                      <template v-if="label.textAfter">
                          &nbsp;{{ label.textAfter }}
                      </template>
                  </el-form-item>
              </el-form>
          </el-row>
          </div>
          <div style="margin-top: 20px;">
            <el-row :span="24">
              <div v-if="isExisted === 'Select Existing Connection'" style="display: flex; align-items: center;">
                <el-select v-model="connectionModel" placeholder="Select any connection" size="large" class="select-box"
                  style="border-radius: 0px; " clearable>
                  <el-option v-for="item in connections" :key="item._id" :label="item.name" :value="item._id"></el-option>
                </el-select> &nbsp; &nbsp;
                <el-dropdown v-if="isExisted === 'Select Existing Connection'" trigger="click" style="float:right;">
                  <el-icon class="more-icon">
                    <MoreFilled />
                  </el-icon>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item @click="updateConnectionDialog = true" :disabled="!connectionModel">
                        <el-icon>
                          <Edit />
                        </el-icon>
                        Update Connection</el-dropdown-item>
                      <el-dropdown-item @click="deleteConnectionDialog = true" :disabled="!connectionModel">
                        <el-icon>
                          <View />
                        </el-icon>
                        Delete Connection</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <el-dialog v-model="updateConnectionDialog" title="Update Connections" width="30%" draggable>
                  <span class="textStyle"> Connection Name </span><br /><br />
                  <el-input size="large" type="textarea" v-model="textareaOfDialog"></el-input>
                  <el-checkbox v-model="updateConnectionsData"> Update Connections Data</el-checkbox><br /><br />
                  <el-button type="primary" size="large" class="button-connect-drawer"
                    :disabled="!updateConnectionsData">Connect with {{
                      selectedApp.app_name }}</el-button>
                  <template #footer>
                    <span class="dialog-footer">
                      <el-button @click="updateConnectionDialog = false">Cancel</el-button>
                      <el-button type="primary" @click="updateConnectionNameEvent">
                        Confirm
                      </el-button>
                    </span>
                  </template>
                </el-dialog>
                <el-dialog v-model="deleteConnectionDialog" title="Do you really want to delete this connection?"
                  width="30%" draggable>
                  This action cannot be undone
                  <template #footer>
                    <span class="dialog-footer">
                      <el-button @click="deleteConnectionDialog = false">Cancel</el-button>
                      <el-button type="primary" @click="deleteConnectionEvent">
                        Confirm
                      </el-button>
                    </span>
                  </template>
                </el-dialog>
              </div>
              <div v-else>
                <el-col>
                  <div>
                    <div v-if="isExisted == 'Select Existing Connection'">
                      <el-select v-model="connectionModel" placeholder="select any connection">
                        <el-option v-for="item in connections" :key="item._id" :label="item._id"
                          :value="item._id"></el-option>
                      </el-select>
                    </div>
                    <div v-else style="display:flex;flex-direction:column;align-items:flex-start">
                      <div>
                        <el-button type="primary" size="large" class="button-connect-drawer"
                          @click="connectToOuath">Connect with {{ selectedApp.app_name }}</el-button>
                      </div>
                      <div>
                        <p style="color:darkgray;font-size:13px;font-weight: 100;">Please authenticate your {{
                          selectedApp.app_name }} app
                          to allow access to Connector.</p>
                      </div>
                    </div>
                  </div>
                </el-col>
              </div>
            </el-row>
          </div>
        </el-card>
      </el-main>

      <el-footer v-if="shouldShowSaveButton"
        style="background-color: #ffffff; display: flex; align-items: center; justify-content: flex-end; bottom: 0;"
        class="drawer-footer">
        <div>
          <el-button @click="cancelClick" style="border-radius: 0px;">cancel</el-button>
          <el-button v-if="isExisted !== 'Select Existing Connection'" type="primary" @click="verifyAndSaveTriggerCredentials"
            style="border-radius: 0px;">Save</el-button>
          <el-button v-if="isExisted === 'Select Existing Connection'" type="primary"
            @click="SaveExisistingConnection">Save</el-button>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import store from '../../store/store';
import { mapGetters } from 'vuex';
import connectionsHelper from '@/mixins/connectionsHelper';
export default {
  props: {
    selectedTriggerAppEvent: {
      type: String,
      required: true
    },
    triggerAppInfo: {
      type: Object
    },
    selectedApp: {
      type: Object,
      required: true
    },
    isTriggerAppConnected: {
      type: Boolean,
      required: true
    },
    isTriggerAppDrawerOpened: {
      type: Boolean,
      required: true
    },
    drawerLogo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isExisted: 'Select Existing Connection',
      tLogo: null,
      connectionModel: null,
      connections: null,
      updateConnectionDialog: false,
      deleteConnectionDialog: false,
      updateConnectionsData: false,
      textarea: '',
      textareaOfDialog: '',
      loading: false,
      outlookCredintials: {
        clientId: null,
        clientSecret: null,
        tenantId: null
      },
      authUrl: null,
      dialogVisible: false,
      queryData: null,
      interval: null,
      allLabels: null
    }
  },
  computed: {
    ...mapGetters([
      "getAllConnections",
      "getOauthServerUrl",
      "getOauthTokens",
      "getValidateAndSaveCredentials",
      "getLabels"
    ]),
    shouldShowSaveButton() {
      const appsToShowSaveButton = ['WrkSpot','Twilio','NimbleStaffing','Apollo'];
      return appsToShowSaveButton.includes(this.selectedApp.app_name) || this.isExisted === 'Select Existing Connection';
    },
    showConnectButton() {
            const { isExisted, appInfo } = this.triggerAppInfo;
            const appName = appInfo.app_name;
            return isExisted !== 'Select Existing Connection' && !this.dontNeedConnectButtonApps.includes(appName);
        },
  },
  created() {
    this.interval = setInterval(this.checkLocalStorage, 2000);
  },
  watch: {
    async queryData(newValue) {
      if (newValue) {
        await this.getTokensUsingAuthorizationCode(newValue)
      }
    },
  },
  async mounted() {
    await this.fetchExistingConnections();
    await this.fetchAndUpdateLabels();
  },
  unmounted() {
    clearInterval(this.interval);
  },
  mixins: [connectionsHelper],
  methods: {
    async checkLocalStorage() {
      let storageInfo = await localStorage.getItem('extractCode')
      this.queryData = await JSON.parse(storageInfo)
      console.log("this.queryData"), this.queryData
      await localStorage.removeItem('extractCode');
    },
    async fetchExistingConnections() {
      this.loading = true
      const userData = localStorage.getItem('user-info')
      let userInfo = JSON.parse(userData)
      let params = {
        user_id: userInfo.user_id._id,
        appName: this.selectedApp.app_name,
      }
      await store.dispatch("fetchExisistingConnections", params)
      this.loading = false
      if (this.getAllConnections.status) {
        if (this.getAllConnections.data && this.getAllConnections.data.length) {
          this.connections = this.getAllConnections.data
          this.textarea = this.selectedApp.app_name + ' ' + '#' + this.connections.length
          this.$notify.success({
            title: "Success",
            message: this.getAllConnections.message,
          });
        }
        else {
          this.$notify.warning({
            title: "Warining",
            message: this.getAllConnections.message,
          });
        }
      }
      else {
        this.$notify.error({
          title: "Error",
          message: this.getAllConnections.message,
        });
      }
    },
    async connectToOuath() {
      if (!this.textarea) {
        this.$message({
          showClose: true,
          message: 'New Connection Name is required.',
          type: 'error'
        });
        return;
      }
      await store.dispatch('getOauthAppServerUrl', this.selectedApp.app_name)
      if (this.getOauthServerUrl && this.getOauthServerUrl.data && this.getOauthServerUrl.data.serviceProviderUrl) {
        let url = `${this.getOauthServerUrl.data.serviceProviderUrl}&state=${this.triggerAppInfo.data._id}`
        window.open(url, '_blank', 'width=800,height=600');
      }
    },
    async getTokensUsingAuthorizationCode(value) {
      let params = {
        queryParams: value,
        user_id: this.triggerAppInfo.data.user_id,
        appName: this.selectedApp.app_name,
        connectionName: this.textarea
      }
      this.loading = true
      await store.dispatch('getOauthTokens', params)
      this.loading = false
      if (this.getOauthTokens.data && this.getOauthTokens.data._id && this.getOauthTokens.data.tokens) {
        this.emitter.emit('setConnection', this.getOauthTokens.data)
        this.$notify.success({
          title: "Success",
          message: "connection established successfully",
        });
        await store.dispatch('sendQueryData', false)
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in establishing connection",
        });
      }
    },
    async deleteConnectionEvent() {
      let connectionId = this.connectionModel._id
      let response = await this.deleteConnection(connectionId)
      if (response.status) {
        let indexValue = await this.connections.findIndex((obj) => obj._id == connectionId)
        this.connections.splice(indexValue, 1);
        this.connectionModel = null
        this.deleteConnectionDialog = false
        this.$notify.success({
          title: "Success",
          message: response.message,
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: response.message,
        });
      }
    },
    async updateConnectionNameEvent() {
      let name = this.textareaOfDialog
      let connectionId = this.connectionModel._id
      let response = await this.updateConnectionName(name, connectionId)
      if (response.status) {
        let matchedId = await this.connections.find((obj) => obj._id == connectionId)
        matchedId.name = name
        this.updateConnectionDialog = false
        this.$notify.success({
          title: "Success",
          message: response.message,
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: response.message,
        });
      }
    },
    async updateConnection() {
      let connectionId = this.connectionModel._id
      let userId = this.triggerAppInfo.data.user_id
      let response = await this.updateConnection(connectionId, userId)
      if (response.status) {
        this.$notify.success({
          title: "Success",
          message: response.message,
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: response.message,
        });
      }
    },
    async SaveExisistingConnection() {
      let macthedConnection = await this.connections.find(connection => connection._id == this.connectionModel)
      this.emitter.emit('setConnection', macthedConnection)
    },
    async fetchAndUpdateLabels() {
            this.loading = true
            await store.dispatch('fetchLabels', this.selectedApp.app_name)
            this.loading = false
            if (this.getLabels.status) {
                this.allLabels = this.getLabels.data
            }
            else {
                return
            }
        },
    async verifyAndSaveTriggerCredentials() {
            const userData = localStorage.getItem('user-info')
            let userInfo = JSON.parse(userData);
            const appName = this.selectedApp.app_name;
            const userId = userInfo.user_id._id;
            let params = {
                labels: this.allLabels,
                eventType: "Trigger",
                triggerId: this.triggerAppInfo.data._id,
                connectionName : this.textarea,
                formModel : this.allLabels.formModel
            }
            this.loading = true;
            await this.$store.dispatch('validateAndSaveCredentials', { appName, userId, params });
            this.loading = false;
            if (this.getValidateAndSaveCredentials && this.getValidateAndSaveCredentials.data && this.getValidateAndSaveCredentials.data._id) {
                let trigger_app_connection_id = this.getValidateAndSaveCredentials.data._id;
                let data = {
                    _id: trigger_app_connection_id,
                    triggerId: this.triggerAppInfo.data._id
                }
                this.emitter.emit('setConnection', data)
                this.$notify.success({
                    title: "Success",
                    message: "connection established successfully",
                });
            } else {
                this.$notify.error({
                    title: "Error",
                    message: "Error in establishing connection",
                });
            }
        },
  }
}
</script>
<style>
.ui-card {
  margin: 20px;
}

.ui-content {
  margin: 20px;
}

.wide-input {
  width: 260px;
}

.info-section {
  color: darkgray;
  font-size: 13px;
  font-weight: 100;
}

.info-text {
  color: darkgray;
  font-size: 13px;
  font-weight: 100;
}

.info-link {
  color: cornflowerblue;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  color: #1b4554;
  position: fixed;
  height: 90px;
  top: 0;
  left: 50;
  right: 0;
  z-index: 999;
}

.drawer {
  top: 0;
  position: fixed;
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 1%;
  height: 80px;
  border: 1px solid #e5e5e5;
  z-index: 1;
  right: 0;
  width: 40%;
}

.drawer-title-p {
  color: #909399;
  font-size: 14px;
  padding-top: 2%;
}

.drawer-title {
  color: #303133;
  font-size: 18px;
}

.drawer-logo {
  border-radius: 0px;
  border: none;
  display: flex;
  align-items: center;
}

.select-box .el-input__inner {
  width: 340px;
}

.oauth-connect-button {
  border-radius: 0px;
}

.drawer-card-header {
  color: #303133;
  font-size: 16px;
}

.drawer-footer {
  bottom: 0;
  right: 0;
  left: 0;
}

.button-connect-drawer {
  border-radius: 0px;
}

.textStyle {
  font-size: 18px;
}
.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
