<template>
  <div v-loading="loading" class="total">
    <el-row :gutter="80">
      <el-col :xl="{ span: 11 }" :lg="{ span: 12 }" :md="{ span: 24 }" :sm="{ span: 24 }">
        <BannerComponent></BannerComponent>
      </el-col>
      <el-col :xl="{ span: 13 }" :lg="{ span: 12 }" :md="{ span: 24 }" :sm="{ span: 24 }">
        <div>
          <div style="margin-top:30px">
            <div class="heading" style="color: darkgray;margin-left:50%;">
              Don't you have an account?
              <router-link to="/signup" style="
                  text-decoration: none;
                  cursor: pointer;
                  color: rgb(25, 137, 250);">Sign up</router-link>
            </div>
            <div class="container" style="width:100%">
              <div style="width:60%">
                <div style="margin-bottom:30px">
                  <h1 class="fs-2 text-dark pb-2">Login</h1>
                  <p style="color: darkgray; font-weight: 100">Greetings!<br> Please enter your credentials</p>
                  <hr />
                </div>
                <el-form label-position="top" :rules="formRules" :model="userDetails">
                  <el-form-item prop="email" label="Email" class="el-form-items">
                    <el-input style=" height:60px;font-size: 1.15em;letter-spacing: .075em;"
                      placeholder="Enter your Email" v-model="userDetails.email"></el-input>
                  </el-form-item>
                  <el-form-item label="password" prop="password" class="el-form-items">
                    <el-input :type="Show ? 'text' : 'password'" style=" height:60px;font-size: 1.15em;letter-spacing: .075em;"
                      placeholder="Enter your password" v-model="userDetails.password">
                      <template #append>
                        <el-button  @click="togglePasswordVisibility()"><el-icon><View /></el-icon></el-button>
                      </template>
                    </el-input>
                  </el-form-item>
                  <el-form-item class="el-form-items">
                    <el-col :span="24">
                      <el-button type="text" class="forgot-button" @click="goToForgotPassword">Forgot
                        Password?</el-button>
                    </el-col>
                  </el-form-item>
                  <el-form-item class="el-form-items">
                    <el-button type="primary"
                      style="height:60px;background-color:hotpink;width:100%;font-weight:bold;font-size:16px"
                      @click="submitForm" :loading="submitLoading">Log in</el-button>
                  </el-form-item>
                  <el-form-item class="el-form-items">
                    <el-button type="primary" style="
                      background-color: #1565d8;
                      border: none;
                      height: 50px;
                      width:100%;
                      font-weight:bold;font-size:16px
                    ">Sign In With Nimble SSO</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import store from '../store/store';
import { mapGetters } from "vuex";
import BannerComponent from '../components/BannerComponent.vue'
export default {
  data() {
    return {
      Show : false,
      submitLoading: false,
      userDetails: {
        email: null,
        password: null
      },
      formRules: {
        email: [
          { required: true, message: 'Email is required', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Email is required', trigger: 'blur' }
        ],
      },
      userResponse: null,
      loading: false
    };
  },
  components: {
    BannerComponent
  },
  computed: {
    ...mapGetters(["getLoginResponse"])
  },
  methods: {
    async submitForm() {
      if (this.userDetails.email && this.userDetails.password) {
        this.submitLoading = true
        try {
          await store.dispatch("userLogin", this.userDetails)
          this.submitLoading = false
          if (this.getLoginResponse.status == false && this.getLoginResponse.message == "This Email doesnot exist!") {
            this.$notify({
              title: 'Warning',
              message: "This Email doesnot exist!",
              type: 'warning'
            });
          }
          else if (this.getLoginResponse.status == false && this.getLoginResponse.message == "wrong credintials!") {
            this.$notify({
              title: 'ERROR',
              message: "wrong credintials!",
              type: 'error'
            });
          }
          else {
            localStorage.setItem('user-info', JSON.stringify(this.getLoginResponse))
            this.$router.push(this.$route.query.redirect || "/connector");
          }
        } catch (err) {
          this.submitLoading = false;
          console.log(err);
        }
      }
    },
    goToForgotPassword() {
      this.$router.push('/forgotPassword');
    },
    togglePasswordVisibility() {
      this.Show = !this.Show;
    },
  }
}
</script>
<style  scoped>
.total {
  margin: 0px;
  padding: 0px
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgot-button {
  float: right;
}
</style>
